@media (max-width: 992px) {
    #image-upload-container {
        .custom-tabs {
            .carousel-slider {
                .slide {
                    .image-slider {
                        height: 370px;
                    }
                }
            }
        }
    }
}


/* Min mobile to max desktop  */
@media(max-width: 1200px) {}

@media(max-width: 1280px) and (min-width: 992px) {}

@media(min-width: 992px) {}


@media(max-width: 788px) {
    .row.statu-bar-container {
        .statu-bar {
            height: 53px !important;

            .statu-bar-text {
                font-size: 11px !important;
            }
        }
    }
}

@media(max-width: 767px) {

    #image-upload-container {
        .custom-tabs {
            .carousel-slider {
                .slide {
                    .image-slider {
                        height: 340px;
                        padding-top: 30px;
                    }
                }
            }

            .attachments-btn {
                p {
                    height: 25px;
                    top: -3px;
                }

                p.download-btn {
                    right: 62px;
                }
            }
        }
    }

}


@media (max-width: 600px) {
    #image-upload-container {
        .custom-tabs {
            .carousel-slider {
                .slide {
                    .image-slider {
                        height: 285px;
                    }
                }
            }
        }
    }
}



@media(min-width: 992px) and (max-width: 1046px) {
    .row.statu-bar-container {
        .statu-bar {
            height: 58px !important;
        }
    }
}

@media(max-width: 495px) {
    #image-upload-container {
        .upload-doc-section {
            .file-upload-image-con {
                .file-upload-image-section {
                    img {
                        width: 100%;

                    }
                }
            }
        }
    }
}

@media (max-width: 466px) {
    #image-upload-container {
        .custom-tabs {
            .carousel-slider {
                .slide {
                    .image-slider {
                        height: 250px;
                        padding-top: 30px;
                    }
                }
            }
        }
    }
}