.fixed-plugin li>a,
.fixed-plugin .badge {
    transition: all .34s;
    -webkit-transition: all .34s;
    -moz-transition: all .34s;
}

.all-icons [class*="pe-"] {
    font-size: 40px;
}

.all-icons input {
    border: 0;
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}

.all-icons .font-icon-detail input {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
}

.places-buttons .btn {
    margin-bottom: 30px
}

.sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.sidebar .nav>li.active-pro a {
    background: rgba(255, 0, 0, 0.4);
    opacity: 1;
    color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
    text-align: center;
}

.fixed-plugin {
    position: absolute;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0, 0, 0, .3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
}

.fixed-plugin .fa-cog {
    color: #FFFFFF;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}

.fixed-plugin .dropdown-menu {
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
}


.service_capture .MuiTableCell-head-376 {
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px !important;
    font-weight: 500;
}

.service_capture .MuiTableHead-root-73 {
    font-size: 17px !important;
}

.service_capture .t1 {
    font-size: 15px;
}

.service_capture .vendortable table thead tr th:nth-child(1) {
    width: 240px;
}

.service_capture .vendortable table thead tr th:nth-child(2) {
    width: 240px;
}

.service_capture .vendortable table thead tr th:nth-child(3) {
    width: 190px;
}

.service_capture .vendortable table thead tr th:nth-child(4) {
    width: 100px;
}

.service_capture .vendortable table thead tr th:nth-child(5) {
    width: 200px;
}

.service_capture .vendortable {
    margin-top: 10px !important;
}

.service_capture .Capture-Service .row .form-group {
    margin-left: 25px !important;
    margin-right: 25px;
}

.service_capture .Capture-Service .row .btn-capture {
    background-color: #8393ca;
    border: 0;
}

.fixed-plugin .fa-circle-thin {
    color: #FFFFFF;
}

.fixed-plugin .active .fa-circle-thin {
    color: #00bbff;
}

.footer-dropdown {
    top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
    top: 300px !important;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
    color: #777777;
    text-align: center;
}

.fixed-plugin img {
    border-radius: 0;
    width: 100%;
    max-height: 175px;
    margin: 0 auto;
}

.fixed-plugin .badge {
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
    border-color: #00bbff;
}

.fixed-plugin .badge-white {
    background-color: #FFFFFF;
}

.fixed-plugin .badge-black {
    background-color: #1DC7EA;
}

.fixed-plugin .badge-azure {
    background-color: #1DC7EA;
}

.fixed-plugin .badge-green {
    background-color: #87CB16;
}

.fixed-plugin .badge-orange {
    background-color: #FFA534;
}

.fixed-plugin .badge-purple {
    background-color: #9368E9;
}

.fixed-plugin .badge-red {
    background-color: #FB404B;
}

.fixed-plugin h5 {
    font-size: 14px;
    margin: 10px;
}

.fixed-plugin .dropdown-menu li {
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
    width: 100%;
    height: 50px;
    min-height: inherit;
}

.fixed-plugin .pro-title {
    margin: 10px 0 5px 0;
    text-align: center;
}

.fixed-plugin #sharrreTitle {
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title {
    height: 30px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
    float: left;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
}

.fixed-plugin .adjustments-line .switch {
    float: right;
}

.fixed-plugin .dropdown-menu>li.adjustments-line>a {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    background-color: #FFF;
    border: 3px solid #FFF;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    cursor: pointer;
    max-height: 86px;
    overflow: hidden;
    padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.switch-trigger:hover,
.fixed-plugin .dropdown-menu>li>a.switch-trigger:focus {
    background-color: transparent;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
    border-color: rgba(0, 187, 255, 0.53);
    ;
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
    border-color: #00bbff;
    background-color: #FFFFFF;
}

.fixed-plugin .dropdown-menu>li>a img {
    margin-top: auto;
}

.fixed-plugin .btn-social {
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}

.fixed-plugin .btn-social i {
    margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
    margin-right: 2%;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    opacity: 1;
}

@media (min-width: 992px) {
    .fixed-plugin .dropdown .dropdown-menu {
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 27px;
        opacity: 0;
        transform-origin: 0 0;
    }

    .fixed-plugin .dropdown.open .dropdown-menu {
        opacity: 1;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        transform-origin: 0 0;
    }

    .fixed-plugin .dropdown-menu:before,
    .fixed-plugin .dropdown-menu:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        width: 16px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
    }

    .fixed-plugin .dropdown-menu:before {
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid rgba(0, 0, 0, 0.2);
        border-top: 16px solid rgba(0, 0, 0, 0);
        right: -16px;
    }

    .fixed-plugin .dropdown-menu:after {
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid #FFFFFF;
        border-top: 16px solid rgba(0, 0, 0, 0);
        right: -15px;
    }

    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }

    .fixed-plugin {
        top: 300px;
    }
}

@media (max-width: 991px) {
    .fixed-plugin .dropdown-menu {
        right: 60px;
        width: 220px;
    }

    .fixed-plugin .dropdown-menu li {
        width: 50%;
    }

    .fixed-plugin li.adjustments-line,
    .fixed-plugin li.header-title,
    .fixed-plugin li.button-container {
        width: 100%;
        height: 55px;
        min-height: inherit;
    }

    .fixed-plugin .adjustments-line .switch {
        float: right;
        margin: 0 0px;
    }

    .fixed-plugin li.header-title {
        height: 40px;
    }

    .fixed-plugin .dropdown .dropdown-menu {
        top: -170px;
    }

    .womt {
        margin-top: 6px;
    }
}

.btn-social {
    opacity: 0.85;
    padding: 8px 9px;
}

.btn-social .fa {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
}

.btn-social.btn-round {
    padding: 9px 10px;
}

.btn-social.btn-simple {
    padding: 9px 5px;
    font-size: 16px;
}

.btn-social.btn-simple .fa {
    font-size: 20px;
    position: relative;
    top: -2px;
    width: 24px;
}

.btn-facebook {
    border-color: #3b5998;
    color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open>.btn-facebook.dropdown-toggle {
    background-color: transparent;
    color: #3b5998;
    border-color: #3b5998;
    opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
    background-color: transparent;
    border-color: #3b5998;
}

.btn-facebook.btn-fill {
    color: #ffffff;
    background-color: #3b5998;
    opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open>.btn-facebook.btn-fill.dropdown-toggle {
    background-color: #3b5998;
    color: #ffffff;
    opacity: 1;
}

.btn-twitter {
    border-color: #55acee;
    color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open>.btn-twitter.dropdown-toggle {
    background-color: transparent;
    color: #55acee;
    border-color: #55acee;
    opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
    background-color: transparent;
    border-color: #55acee;
}

.btn-twitter.btn-fill {
    color: #ffffff;
    background-color: #55acee;
    opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open>.btn-twitter.btn-fill.dropdown-toggle {
    background-color: #55acee;
    color: #ffffff;
    opacity: 1;
}

@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.all-icons [class*="pe-"] {
    font-size: 40px;
}

.all-icons input {
    border: 0;
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}

.all-icons .font-icon-detail input {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
}

.places-buttons .btn {
    margin-bottom: 30px
}

.sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.sidebar .nav>li.active-pro a {
    background: rgba(255, 0, 0, 0.4);
    opacity: 1;
    color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
    text-align: center;
}

.dx-datagrid {
    font: 9px verdana;
}

table thead tr:nth-child(1) {
    background-color: #c2c2c2 !important;
}

tbody tr:nth-child(even) {
    background: #ededed;
}

div[class^=MuiTooltip] {
    font-size: 12px !important;
}


div[id^=mui-tooltip] {
    font-size: 12px !important;
}


button.custom-table-edit-button {
    padding: 0px 7px !important;
}

div[class^=TableSummaryItemBase] {
    font-size: 14px !important;
}

.sidebar-wrapper ul li a {
    padding-right: 0;
}

/*cursor pointer for all table*/

table tbody tr a:hover {
    cursor: pointer;
}

.print-btn {
    background-color: #747474 !important;
}

.edit-btn {
    border-color: #177BF6 !important;
    background-color: #177BF6 !important;
}

.btn-danger {
    border-color: #C42F2B !important;
    background-color: #C42F2B !important;
}

.btn-add {
    border-color: #8493CA !important;
    background-color: #8493CA !important;
}

.delete-btn-product {
    border-radius: 50%;
    font-size: 11px;
    width: 25px;
    height: 25px;
    padding: 0 !important;
}

.history-btn-product {
    border-radius: 50%;
    font-size: 11px;
    width: 25px;
    height: 25px;
    padding: 0 !important;
}

.product-table {
    width: 100%;
    overflow: auto;
}

.info-icon {
    color: #337ab7 !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

.apply-btn {
    background-color: #3472F7 !important;
    margin-top: 18px !important;
    border-color: #3472F7 !important;
    padding: 8px 18px !important;
}
.apply-btn-secondary {
    margin-top: 18px !important;
    padding: 8px 18px !important;
}

.apply-btn-inline {
    background-color: #3472F7 !important;
    margin-top: 0px !important;
    border-color: #3472F7 !important;
    padding: 8px 18px !important;
}

@media(max-width:1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    /* .product-edit thead th:last-child {
        width: 200px !important;
        padding-right: 30px !important;
    } */

    .product-edit tbody tr td:nth-child(3) input {
        width: 100px !important;
    }
}

.workOrder-padding-info {
    margin-left: 10px;
}

.nav li {
    margin-top: 2px !important;
}

@media(max-width: 1225px) {
    td .history-btn-product {
        margin-left: 0px !important;
        display: block;
        margin-top: 4px;
    }

    td .delete-btn-product {
        margin-left: 0px;
    }
}

@media(max-width:1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .workOrder-padding-info {
        margin-left: 0% !important;
    }
}

.btn-verify {
    background-color: #006b38 !important;
    border-color: #006b38 !important;
    text-transform: none;
}

.btn-add-workorder {
    margin-right: 2px;
    margin-bottom: 6px;
    font-size: 12px;
    padding: 5px 10px;
}

#alert-dialog-title h2 {
    font-size: 16px !important;
}

.sidebar .childnav {
    margin-bottom: 0;
    list-style: none;
    padding-left: 35px;
    background-color: #ffffff3d;
    opacity: 0.7;
    margin-top: -2px;
    border-radius: 0px 0px 4px 3px;
}

.sidebar .childnav ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0px;
    background: rgba(255, 255, 255, 0.10);
}

.sidebar .childnav>li {
    position: relative;
    display: block;
    padding-top: 1px;
    padding-bottom: 1px;
}

.sidebar .childnav li>a {
    color: #FFFFFF;
    margin: 5px 15px;
    opacity: .86;
    position: relative;
    display: block;
    padding: 5px 10px;
}

.react-bs-table-no-data {
    text-align: center
}

.liparent>a {
    border-radius: 4px 4px 0px 0px;
    background: rgba(255, 255, 255, 0.13);
}

/* .active.liparent{
    border-radius: 4px 4px 0px 0px;
    background: rgba(255, 255, 255, 0.13);
} */
/* li a.active{
    background: rgba(255, 255, 255, 0.13)!important;
} */

.active.linonparent>a {
    border-radius: 4px;
}

.parentclick>a {
    background-color: rgba(241, 231, 231, 0.5) !important;
}

.sidebar .childnav li:hover>a,
.sidebar .childnav li.open>a,
.sidebar .childnav li.open>a:focus,
.sidebar .childnav li.open>a:hover {
    background: rgba(255, 0, 0, 0.2);
    opacity: 1;
}

.sidebar .childnav li.active>a {
    color: #FFFFFF;
    opacity: 1;
    background: rgba(255, 0, 0, 0.6);
}

.sidebar .childnav p {
    margin: 0;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.sidebar .childnav .caret {
    top: 24px;
    position: absolute;
    right: 15px;
}

.sidebar .childnav i {
    font-size: 28px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center;
}

.mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.print-wrapper .rightside .print-right-label-width {
    width: 115px;
}

.print-wrapper .rightside-value label {
    font-weight: normal !important;
}

.print-wrapper .leftside-label {
    width: 110px;
}

.mb-10px {
    margin-bottom: 10px !important;
}

.nos-unit {
    float: left;
    padding-left: 5px;
    padding-top: 10px;
}

.editWo-inputWidth,
.new_work_order_edit {
    float: left;
}

@media(min-width: 767px) {
    .desktop-d-flex {
        display: flex;
    }

    .desktop-d-flex .col-md-7,
    .desktop-d-flex .col-md-5 {
        background: white;
        margin: 15px;
        border: 1px solid #ddd;
    }

    .desktop-d-flex .col-md-7 .card,
    .desktop-d-flex .col-md-5 .card {
        box-shadow: unset;
    }

    .workorder-top-details .mb-4 {
        margin-bottom: 25px;
    }

    .workorder-top-details .mb-4:last-child {
        margin-bottom: 0;
    }

    .workorder-top-details .purchase .mb-10px {
        margin-bottom: 18px !important;
    }

    .mtop--7px {
        margin-top: -7px;
    }
}

@media(min-width: 992px) {
    .width-122px {
        /* width: 122px; */
        width: 20%;
    }
}

@media(max-width: 1335px) and (min-width: 992px) {
    .width-122px {
        /* width: 122px; */
        width: 22%;
    }

    .workorder-top-details .col-md-4.width-md {
        width: 22%;
    }
}

/* @media(max-width: 550px) {
    table.product-edit .history-btn-product {
        margin-left: 0 !important;
    }
} */

.card-body-padding {
    padding-top: 15px;
}

.react-datepicker__close-icon::after {
    background-color: #333;
}

.table>thead:first-child>tr:first-child>th {
    font-weight: bold;
    color: #333;
}

.rows-con svg {
    top: 6px;
}

@media(max-width: 1130px) and (min-width: 992px) {
    .countdiv .dashboard-countValue {
        font-size: 12px !important;
    }
}

/*---------------------------DashboardFilter----------------------------*/
.dashboard-title {
    margin-top: 15px !important;
    margin-bottom: 5px !important;
    float: left;
    font-size: 18px;
}

.mob-filter_icon {
    display: none;
}

@media(max-width:1200px) {
    .filter_icon {
        display: none;
    }

    .mob-filter_icon {
        display: block;
    }
}

.filter_icon {
    width: 20px;
    float: left;
    margin: 25px 0px 0px 10px;
}

.mob-filter_icon {
    position: absolute;
    top: 7px;
    width: 15px;
}

.padding-custom-15px {
    padding: 15px;
}

@media(min-width:1200px) {

    .lg-pl-5px {
        padding-left: 5px !important;
    }
}

.control-label {
    color: #696969 !important;
    font-size: 14px !important;
    text-transform: none !important;
    font-weight: bold !important;
}

.react-bootstrap-daterangepicker-container {
    display: flex !important;
}

.dashboard-filter .col-sm-6 {
    width: 50%;
}

@media (min-width: 1200px) {
    .dashboard-filter .col-lg-2 {
        width: 22%;
    }

    .dashboard-filter .col-lg-3 {
        width: 25%;
    }

    .dashboard-filter .col-lg-4 {
        width: 30%;
    }

}

@media (max-width: 600px) {
    .dashboard-filter .col-xs-12 {
        width: 100%;
    }

    .xs-w-100px {
        width: 100%;
    }

    .xs-pl-15px {
        padding-left: 15px !important;
    }
}

@media(max-width: 767px) {
    .xs-mb-30px {
        margin-bottom: 30px !important;
    }

    .xs-clear-both {
        clear: both;
    }
}

.MuiFormLabel-root-1 {
    line-height: 1;
}

.mt-8px {
    margin-top: 8px !important;
}

.mt-5px {
    margin-top: 5px !important;
}

.mt-10px {
    margin-top: 9.5px !important;
}

.mt-7px {
    margin-top: 7px !important;
}

.col-lg-2,
.col-lg-3 {
    float: left;
}

.form-control.date-range-picker,
.form-control.borderradius {
    height: 38px;
}

.dashborad-filter .react-bootstrap-daterangepicker-container .fa.fa-calendar,
.dashborad-filter .form-control.borderradius .fa.fa-times {
    margin-top: 3px;
    cursor: pointer;
}

.react-bootstrap-daterangepicker-container .form-control.date-range-picker span {
    white-space: nowrap !important;
    width: 80% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block;
}

@media(max-width: 1200px) and (min-width: 992px) {
    .react-bootstrap-daterangepicker-container .form-control.date-range-picker span {
        width: 69% !important;
    }
}

.d-flex {
    display: flex;
}

.position-relative {
    position: relative;
}

.pull-right-position {
    position: absolute;
    right: 0;
}

.title-card-padding {
    padding: 8px 30px 25px 30px;
}

.workorder table tbody input[type="number"] {
    padding: 8px 5px 8px 12px;
    width: 100%;
}

.workorder table tbody .new_work_order_edit {
    width: 75px;
}

.cl-dashboard-filter {
    padding-left: 13.5px;
}

.cl-dashboard-filter .css-bg1rzq-control div div[class^="css-dv"] {
    color: #767676;
}

.cl-dashboard-filter #reportrange span {
    color: #767676;
}

.daterangepicker .ranges li {
    font-size: 13.5px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.countdiv.row .card {
    box-shadow: 0px 1px 10px 0px #bebebe;
}

.cl-dashboard-filter .form-group label {
    font-weight: 500 !important;
    font-size: 14.5px !important;
}

.filter-label-title {
    padding-left: 15px;
    font-weight: bold;
    font-size: 16px;
    color: rgb(100, 100, 100);
}

.mt-0 {
    margin-top: 0 !important;
}

/* .mt-1px {
    margin-top: 1px!important;
} */

.mb-10px {
    margin-bottom: 10px;
}

.mr-2 {
    margin-right: 1em;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-2 {
    margin-left: 1em !important;
}

.cp-titlelabel {
    position: absolute;
    padding-left: 0px;
    padding-top: 8px;
}

.cl-dashboard-filter .form-group {
    margin-bottom: 5px;
}


.mb-5px {
    margin-bottom: 5px !important;
}

.mb-4px {
    margin-bottom: 4px !important;
}

/* .page-heading{
    overflow: auto;
} */

/*-------------------------WorkOrder List----------------------*/
.title-breadcrumb {
    float: left;
    margin-top: 0px;
    margin-left: 14.5px;
}

.page-heading .btn-add-workorder {
    margin-bottom: 5px;
}

.new-workorder-btn,
.survey-actionButtons {
    float: right;
}

.new-workorder-btn button.btn {
    font-size: 12px;
    padding: 5px 10px;
}

@media(max-width: 440px) {
    .table-cover div:nth-child(1) {
        display: initial;
    }

    .btn-add-workorder {
        margin-right: 0px;
    }
}

.print-header {
    display: block;
    text-align: center;
}

.print-table-title {
    font-size: 20px;
    display: block;
    margin-bottom: 5px;
    margin-left: -5px;
}

@media(max-width:768px) {
    #main-panel .page-heading .sm-pt-7px {
        padding-top: 7px !important;
    }
}

/*-------------------------NewDealer-----------------------*/

@media(max-width: 992px) {

    .Dealer_Add .form-group,
    .Dealer_Edit .form-group,
    .Vendor_Add .form-group,
    .Vendor_Edit .form-group,
    .Supply_Edit .form-group {
        margin-left: 0px !important;
    }

    .tablediv#vendortable {
        margin-top: 10px !important;
    }

    .Vendor_Add.card-body .add-prd-div .button-group,
    .Supply_Edit.card-body .edit-prd-div .button-group {
        margin-top: 10px;
    }

    .Supply_Edit .form-group.md-pt-10px {
        padding-top: 10px;
    }

}

.vertical-align-middle {
    vertical-align: middle;
}

.table>thead>tr>th {
    text-transform: none !important;
}

/* svg > g > g:last-child {
    pointer-events: unset;
} */

table tfoot {
    display: table-row-group;
}

.xs-flexRow {
    display: flex;
    flex-wrap: wrap;
}

a {
    color: #1568d1;
}

.form-control,
div[class^=css-bg1r] {
    border: 1px solid #d3d3d3 !important;
}

@media(max-width: 992px) and (min-width:780px) {
    .add-prd-div .md-pt-10px {
        padding-top: 10px;
    }
}

/*------------------------Vendor-View--------------------*/

@media(max-width:1024px) {
    .Vendor_View.card-body .row .col-md-6 .form-group {
        margin-left: 0% !important;
    }
}


/*-----------------------Dealer-View---------------------*/
@media(max-width:1024px) {
    .dealer_view.card-body .row .col-md-6 .form-group {
        margin-left: 0% !important;
    }
}


.tablediv table tbody a {
    color: #1765d9 !important;
}

.logout-icn a {
    padding-right: 0 !important;
    margin-right: 0 !important;
}

.align-center {
    text-align: center !important;
}

.inline-block {
    display: inline-block;
}

.mt-8px {
    margin-top: 8px;
}

.pr-5px {
    padding-right: 5px;
}

.pt-5px {
    padding-top: 5px;
}

.lh-15 {
    line-height: 1.5 !important;
}

.custom-filter {
    flex-wrap: wrap;
}

.Reportdealer-filter #reportrange {
    padding-left: 7px !important;
    color: #808080;
}

.Reportdealer-filter #reportrange i,
.reportarea-filter #reportrange i {
    top: 10px;
    margin-top: 1px;
}

.reportarea-filter #reportrange i {
    margin-top: 3px;
}

table tbody td div input+span,
table tbody td div select+span {
    font-size: 13px;
}

.display-block {
    display: block;
}

.my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
}

#dealershipreporttable div table thead tr th div span {
    color: #333;
    font-size: 15px !important;
}

.flexWrap {
    flex-wrap: wrap;
    display: flex;
}

@media(min-width:1250px) {
    .cust-width-filter {
        width: 965px;
    }
}

@media(min-width: 1200px) {
    .dashborad-filter .custom-filter .col-lg-5 {
        width: 41.66666667%;
    }

    .lg-pr-0 {
        padding-right: 0 !important;
    }

    .custom-filter .col-lg-4 {
        width: 33.33333333%;
    }

    .md-58p {
        width: 58% !important;
    }
}

@media(max-width: 1200px) {
    .md-pl-15px {
        padding-left: 15px !important;
    }
}

@media(max-width: 743px) {
    .history-btn-product {
        margin-top: 3px;
    }

    .delete-btn-product {
        margin-left: 5px;
    }
}

@media(max-width: 1199px) {
    .md-my-10px {
        margin: 10px 0 !important;
    }
}

@media(min-width: 992px) {
    .col-lg-105px {
        width: 105px !important;
    }

    .col-lg-100px {
        width: 100px !important;
    }

    .custom-filter .col-lg-160px {
        width: 150px;
    }

    .custom-filter .col-lg-150px {
        width: 150px;
    }

    .dashborad-filter .custom-filter .col-md-8 {
        width: 66.66666667%;
    }
}

@media(max-width:767px) {
    .xs-pl {
        padding-left: 15px !important;
    }

    .sm-whiteSpace-nowrap {
        white-space: nowrap;
    }
}

.main-panel>.content {
    padding-top: 10px !important;
}

.popup-close-btn span {
    font-size: 13px;
}

.mt-10px {
    margin-top: 10px;
}

/*------------------------Table CSS-----------------------*/
.w-35px {
    width: 31px;
}

.w-71px {
    width: 71px;
}

.apply-btn-div {
    width: 10.333333%;
}

/* #serviceTable .table-cover div:nth-child(1){
    display: inherit;
} */

table tr:nth-child(2) .react-bootstrap-daterangepicker-container span {
    font-size: 13.5px !important;
}

table thead tr:nth-child(2) #reportrange,
table thead tr:nth-child(2) .form-control.borderradius {
    margin-top: 0 !important;
}

.table-cover div[class^=RootBase] div:nth-child(3) div[class^=PageSizeSelector] span {
    font-size: 14px;
}

.tablediv table thead tr {
    background-color: #d3d3d3 !important;
}

.approval-pending-table table thead tr th,
#report_table table thead th {
    border-bottom: 0 !important;
}

.tablediv div table thead tr th select.form-control {
    font-size: 13.5px;
}

.tablediv table thead tr:first-child th span {
    font-size: 15px;
}

.approval-pending-table tbody tr td {
    white-space: nowrap;
}

#dealerTable thead tr:first-child,
#vendortable thead tr:first-child,
#productTable thead tr:first-child,
#mat-custom-Table thead tr:first-child,
#workOrderTableCon thead tr:first-child,
#survey_list_container thead tr:first-child,
#purchaseordertable thead tr:first-child {
    background-color: #d3d3d3 !important;
    height: 30px;
}

#dealerTable thead tr:nth-child(2),
#vendorTable thead tr:nth-child(2),
#productTable thead tr:nth-child(2),
#mat-custom-Table thead tr:nth-child(2),
#workOrderTableCon thead tr:nth-child(2),
#survey_list_container thead tr:nth-child(2),
#purchaseordertable thead tr:nth-child(2) {
    background-color: #d3d3d3 !important;
}

/* .tablediv .MuiToolbar-root {
    padding-bottom: 0px !important;
    min-height: 0px !important;
} */

#dealerTable thead tr:first-child th,
#vendorTable thead tr:first-child th,
#productTable thead tr:first-child th,
#mat-custom-Table thead tr:first-child th,
#workOrderTableCon thead tr:first-child th,
#survey_list_container thead tr:first-child th,
#purchaseordertable thead tr:first-child th,
#serviceTable thead tr:first-child th {
    padding-bottom: 0px;
    border-bottom: none;
    text-align: center;
}

#dealerTable div[class^="TableContainer"],
#vendortable div[class^="TableContainer"],
#productTable div[class^="TableContainer"],
#workOrderTableCon div[class^="TableContainer"],
#survey_list_container div[class^="TableContainer"],
#purchaseordertable div[class^="TableContainer"],
.tablediv.workOrderTable .MuiToolbar-root div {
    overflow-y: hidden;
}

#dealerTable table thead tr:first-child th,
#vendortable table thead tr:first-child th,
#productTable table thead tr:first-child th,
#mat-custom-Table table thead tr:first-child th,
#workOrderTableCon table thead tr:first-child th,
#survey_list_container table thead tr:first-child th,
#purchaseordertable table thead tr:first-child th {
    text-align: left !important;
}

#dealerTable thead tr:nth-child(2) th input,
#dealerTable thead tr:nth-child(2) th select,
#vendortable thead tr:nth-child(2) th input,
#vendortable thead tr:nth-child(2) th select,
#productTable thead tr:nth-child(2) th input,
#productTable thead tr:nth-child(2) th select,
#mat-custom-Table thead tr:nth-child(2) th input,
#mat-custom-Table thead tr:nth-child(2) th select,
#workOrderTableCon thead tr:nth-child(2) th input,
#workOrderTableCon thead tr:nth-child(2) th select,
#survey_list_container thead tr:nth-child(2) th input,
#survey_list_container thead tr:nth-child(2) th select,
#purchaseordertable thead tr:nth-child(2) th input,
#purchaseordertable thead tr:nth-child(2) th select,
.approval-pending-table table thead th input,
.approval-pending-table table thead th select {
    margin-top: 0 !important;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-size: 13.5px;
}

#dealerTable div table thead tr th div span,
#vendortable div table thead tr th div span,
#productTable div table thead tr th div span,
#mat-custom-Table div table thead tr th div span,
#workOrderTableCon div table thead tr th div span,
#survey_list_container div table thead tr th div span,
#purchaseordertable div table thead tr th div span,
.tablediv.recenttablediv table thead tr th span,
.approval-pending-table table thead tr th span {
    font-size: 15px;
}

#dealerTable div table tbody tr td,
#vendortable div table tbody tr td,
#productTable div table tbody tr td,
#mat-custom-Table div table tbody tr td,
#workOrderTableCon div table tbody tr td,
#survey_list_container div table tbody tr td,
#purchaseordertable div table tbody tr td {
    font-size: 14px !important;
}

#dealerTable tbody tr td,
#workOrderTableCon tbody tr td {
    white-space: nowrap;
}

#dealerTable table thead tr:first-child th,
#dealerTable table tbody td,
#vendortable table thead tr:first-child th,
#vendortable table tbody td,
#productTable table thead tr:first-child th,
#productTable table tbody td,
#mat-custom-Table table thead tr:first-child th,
#mat-custom-Table table tbody td,
#workOrderTableCon table thead tr:first-child th,
#workOrderTableCon table tbody td,
#survey_list_container table thead tr:first-child th,
#survey_list_container table tbody td {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#dealerTable table tbody td,
#vendortable table tbody td,
#productTable table tbody td,
#mat-custom-Table table tbody td,
#workOrderTableCon table tbody td,
#survey_list_container table tbody td,
#purchaseordertable table tbody td {
    color: #2a2a2a;
    font-weight: 500;
}

#dealerTable div[class="SortLabel"],
#vendortable div[class="SortLabel"],
#productTable div[class="SortLabel"],
#mat-custom-Table div[class="SortLabel"],
#workOrderTableCon div[class="SortLabel"],
#survey_list_container div[class="SortLabel"],
#purchaseordertable div[class="SortLabel"] {
    text-align: center;
}

#dealerTable table tbody tr,
#vendortable table tbody tr,
#productTable table tbody tr,
#mat-custom-Table table tbody tr,
#workOrderTableCon table tbody tr,
#survey_list_container table tbody tr,
#purchaseordertable table tbody tr,
.tablediv tbody tr {
    height: 42px;
}

#dealerTable table tbody .edit-btn,
#vendortable table tbody .edit-btn,
#productTable table tbody .edit-btn,
#mat-custom-Table table tbody .edit-btn,
#purchaseordertable table tbody .edit-btn,
#workOrderTableCon table tbody .edit-btn,
#workOrderTableCon table tbody .btn-primary,
.recenttablediv table tbody .btn-primary,
.approval-pending-table table tbody .btn-primary {
    border-color: transparent !important;
    background-color: none !important;
    background: none !important;
    padding: 2.5px 10px;
}

table .btn-nopadding button.edit-btn,
table .btn-nopadding button.delete-btn,
table .btn-nopadding button.btn-info,
table .btn-nopadding button.btn-danger,
.btn-nopadding .btn.btn-fill {
    padding: 0 !important;
    width: 35px;
}

.btn-nopadding button i.fa-edit {
    color: #1568d1;
    font-size: 19px;
}

.btn-nopadding button i.fa-trash-alt {
    color: #C42F2B;
    font-size: 19px;
}

.btn-nopadding button i.fa-eye {
    color: #7451ae;
    font-size: 19px;
}

#dealerTable table tbody .btn-danger.btn-fill,
#vendortable table tbody .delete-btn,
#productTable table tbody .btn-danger.btn-fill,
#productTable table tbody .btn-info.btn-fill,
#mat-custom-Table table tbody .btn-danger.btn-fill,
#mat-custom-Table table tbody .btn-info.btn-fill,
#workOrderTableCon table tbody .btn-info.btn-fill,
#workOrderTableCon table tbody .btn.btn-fill,
#purchaseordertable table tbody .btn-danger.btn-fill,
.recenttablediv table tbody .btn-danger.btn-fill {
    background-color: none !important;
    border-color: transparent !important;
    background: none !important;
    padding: 2.5px 10px;
}

/* #dealerTable table button.btn,
#vendortable table button.btn,
#productTable table button.btn,
#workOrderTableCon table button.btn {
    border-radius: 20px;
    /* box-shadow: 0px 2px 8px -3px #000; 
}
 */
table .btn-circle {
    width: 24px !important;
    height: 24px !important;
    padding: 0px 0px 0px 0px !important;
}

.countdiv img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
    /*  filter: invert(1);
    -webkit-filter: invert(1) contrast(500%); */
}

.actioncolumn div[class^="SortLabel"],
.actioncolumn div {
    text-align: center !important;
}

.Vendor_Add.card-body hr,
.Supply_Edit.card-body hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.workorder.Wo-Edit tbody tr td button i {
    font-size: 11.5px;
}

.workorder.Wo-Edit tbody tr td button,
.product-edit button.delete-btn {
    padding: 5px 0.5px !important;
}

.btn-verify-woList i {
    color: #87cb16;
    font-size: 19.5px;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.btn-sm {
    font-size: 13px !important;
}

@media(max-width: 1290px) {
    #main-panel .content .responsive-card-container .max-lg-pt-15px {
        padding-top: 15px !important;
    }
}

@media(max-width:780px) {
    .workorder.Wo-Edit tbody select.form-control {
        width: 180px;
    }

    .workorder.Wo-Edit .edit-unitPriceBox {
        width: 70px;
    }

    .sm-clear-left {
        clear: left;
    }

    .table-responsive.purchaseOrder {
        border: unset;
    }

    .sm-mb-10px {
        margin-bottom: 10px;
    }

    .smtop-7px {
        margin-top: -7px;
        top: 0 !important;
    }

    .sm-mb-5px {
        margin-bottom: 5px;
    }

    .workorder.Wo-Edit .workorder.Wo-Edit {
        width: 150px !important;
    }

    .workorder.Wo-Edit .wo-editQtyBox {
        min-width: 150px !important;
    }

    .edit-supplyItem .datePicker-xs {
        white-space: nowrap;
    }

    .table.product-edit .newSupplyItem {
        white-space: nowrap;
    }

    .sm-pl-7px {
        padding-left: 7px;
    }

    .sm-pt-10px {
        padding-top: 10px !important;
    }
}

.POtable .cancel-css span {
    /* background: red; */
    padding: 0px;
    border-radius: 20px;
}

.POtable .cancel-css {
    padding: 5px;
}

.POtable .cancel-css:hover,
.POtable .cancel-css {
    background: red !important;
}

.pt-0px {
    padding-top: 0px;
}

.nodatafound {
    text-align: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.service_capture .vendortable.ls-mt-0px {
    margin-top: 0px !important;
}

.wo-edit-card .card {
    margin-bottom: 10px !important;
}

.col-md-5.col-sm-12.mt-10px.mlmb {
    margin-left: 8px;
    margin-bottom: 4px;
}

@media (max-width: 767px) {
    .col-md-5.col-sm-12.mt-10px.mlmb {
        margin-left: 0px;
    }

}

.womt {
    margin-top: 6px;
}

.srmt {
    margin-top: 5px;
}

@media (max-width: 1280px) {
    .srmt {
        margin-top: 0px;
    }
}

.actioncolumn {
    text-align: -webkit-center !important;
}

#serviceTable thead th input.form-control,
#serviceTable thead th select.form-control {
    margin-top: 0px !important;
}

@media(max-width:767px) {
    .export-btn {
        display: none;
    }

}

.mt-10px.sm-mb-10px.inmbmr {
    margin-bottom: 4px;
    margin-right: 4px;
}

.col-md-5.col-sm-12.mt-10px.sm-mb-5px.sm-clear-left.vmbml {
    margin-bottom: 4px;
    margin-left: 4px;
}

.mt-5px.woembmr {
    margin-bottom: 4px;
    margin-right: 5px;
}

.mt-5px.woembml {
    margin-bottom: 4px;
    margin-left: 4px;
}

@media(max-width:766px) {
    .mt-10px.sm-mb-10px.inmbmr {
        margin-bottom: 10px;

    }

    .col-md-5.col-sm-12.mt-10px.sm-mb-5px.sm-clear-left.vmbml {

        margin-left: 0px;
    }

    .mt-5px.woembmr {

        margin-right: 0px;
    }

    .mt-5px.woembml {

        margin-left: 0px;
    }
}

.mt-2px {
    margin-top: 2px;
}

.scamb-4px {
    margin-bottom: 4px;
}

.polmbpt {
    margin-bottom: 5px;
    padding-top: 0px;
}

.radtop6 {
    top: 6px;
}

.edit-prd-div.pebgm {
    background-color: #eee;
    margin: 5px 0;
    padding: 5px 0 0 0;
}

@media(max-width:320px) {
    .btn.btn-fill.btn-secondary.btn-eoe-mt {
        margin-top: 10px;
    }
}

.btn.btn-verify.btn-sm.d-appr-btn {
    color: white;
    opacity: 1;
}

.btn.btn-fill.btn-md.btn-verify-btn.pov-update {
    background-color: #3472F7;
    border-color: #3472F7;
}

/*-----------Remove Sroll Print---------------*/
@media print {
    #report_table div div hr+div div {
        overflow: unset;
    }
}

/*--------------------------------------*/

.Add-New-Section {
    padding-right: 13px;
    padding-top: 14px;
}

.Add-New-Icon {
    font-size: 22px;
}

.AddAccidentBtn {
    text-decoration: underline;
    padding: 0px 0px !important;
}

.section {
    margin-top: 0px !important;
}

.row-heading-line.row {
    padding-top: 10px;
    text-align: right;
}

.row-heading-line.row::before {
    content: '';
    border-top: 1px solid #e6e6e6;
    width: 98%;
    margin-left: 10px;
    padding-bottom: 10px;
}

.row-heading-line .remove-icon-con {
    padding: 0px 19px 0px 0px;
    width: 100%;
}

.row-heading-line .remove-icon-con .close-icon {
    display: inline-block;
    padding: 5px 4px 5px 4px;
    border: 1px solid #ccc;
    color: red;
    border-radius: 50%;
    cursor: pointer;
}

.incident_view h5,
.Add_Accident h5,
.dealer_service_master_container h5,
.incident_edit h5 {
    font-size: 16px !important;
    margin-bottom: 4px !important;
    font-weight: bold;
    color: #736dc3 !important;
}

.incident-no h5 {
    font-size: 16px !important;
    margin-bottom: 4px !important;
    font-weight: bold;
    color: #777 !important;
}

.card .control-label {
    word-break: break-all;
}

.Safety-Policy-Main-Header label {
    font-size: x-large !important;
}

.Safety-Policy-Main-Header {
    text-align: center;
}

.Safety-Policy-Header label {
    font-size: large !important;
    margin-bottom: 0px !important;
}

.Safety-Sub-Data .control-label {
    padding-left: 85px;
}

/* 
.safetyPolicy_view .row .col-12 .form-group, .safetyPolicy_view .row .col-6 .form-group{
    margin-bottom: 5px;
} */

.safety-edit-btn {
    border-color: transparent !important;
    background-color: none !important;
    background: none !important;
}

.safetyPolicy_view_heading .safety-edit-btn {
    float: right;
    margin-top: -20px;
}

.TextArea .control-label {
    width: 100%;
}

.TextArea textarea {
    height: auto;
    width: 100%;
}

.border_top{
margin: 0px 1px 0px 1px;
/* padding: 0px 10px 0px 10px; */
}