$IconsPath: "../img/default-avatar.png";

.astriek {
    color: red;
}

.dashboard-title {
    margin-top: 0px;
    margin-bottom: 15px;
}

ul.nav li.navitemlink a:hover,
ul.nav li.navitemlink a:focus {
    color: #9a9a9a !important;
    cursor: unset;
}

.remove-margin {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.custom-padding {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

table {
    td.inline-edit-section {
        .btn-fill {
            background-color: transparent;
            border: none;
            margin: 0px -3px 1px 0px;
            color: #3472f7;
            font-size: 12px;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                color: #3472f7;
            }

            .table-survey-button {
                margin: -5px;
            }
        }
    }
}

.serviceDesc-modal {
    .serviceDesc-modal-container {
        margin-top: 0px;
        padding: 0px 30px 70px 30px !important;
        overflow: visible;
    }

    .serviceDesc-modal-footer {
        justify-content: center !important;
        padding-bottom: 0px;
        display: block !important;
        text-align: center;

        .btn.btn-fill {
            margin-bottom: 10px;
        }
    }

    &.Autohight {
        [class^="MuiPaper-root-"] {
            height: 480px;
        }
    }

    .MuiDialog-container {
        .MuiPaper-root {
            overflow: visible;
        }
    }
}

.status_class {
    padding: 10px;
}

.react-bootstrap-daterangepicker-container {
    width: 100%;
    cursor: pointer !important;
}

.access-denied-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70%;

    .row {
        width: 100%;

        .access-denied {
            display: block;
            text-align: center;
        }
    }
}

span.navigation-link {
    color: #3472f7;
    font-weight: 600;
    cursor: pointer;
}

.custom-inline-edit-icon {
    cursor: pointer;

    img.table-survey-button {
        margin: 0;
        margin-top: -5px;
    }

    .save_icon {
        margin: 0px 0px 0px -4px;
        padding: 8px 0 0 0;
    }
}

.POtable {
    .paper-form {
        div {

            .col-md-2,
            .col-md-4 {
                div {
                    font-weight: 500 !important;
                }
            }
        }
    }
}

.diabled-field {
    background-color: #eeeeee61 !important;
}

.date-range-picker {
    background: #fff !important;
    cursor: pointer !important;
    padding: 8px 3px !important;
    border: 1px solid #ccc !important;
    /* margin-top: 5px; */
    /* width: 100%!important;
    width: 260px!important; */
}

.borderradius1 {
    border-bottom-right-radius: unset !important;
    border-top-right-radius: unset !important;
    border-right: 0px !important;
}

// .POtable div[class^="TableSummaryItemBase"]
// {
//   font-weight: bold;
//   font-size: 14px !important;
// }
.POtable table tfoot tr td:nth-child(4) div {
    font-weight: bold;
}

.POtable .table-responsive {
    width: 100%;
}

.borderradius {
    border-bottom-left-radius: unset !important;
    border-top-left-radius: unset !important;
    border-left: 0px !important;
}

.tablediv {
    //margin-top: 5px !important;

    .Rootbase {
        display: unset !important;
    }

    .MuiToolbar-root {
        padding-bottom: 10px !important;
        min-height: 10px !important;
    }

    .MuiToolbar-root1 {
        padding-bottom: 10px !important;
        min-height: 35px !important;
    }

    .MuiToolbar-root2 {
        padding-bottom: 0px !important;
        min-height: 0px !important;
    }

    .cp-titlelabel {
        padding-top: 0px !important;
        margin-top: 5px !important;
    }

    div table {
        thead {
            tr {
                height: 35px;

                th {
                    padding: 0;
                    padding-left: 10px;

                    div span {
                        font-size: 14px;
                    }

                    input.form-control,
                    select.form-control {
                        background-color: #ffffff;
                        border: 1px solid #e3e3e3;
                        border-radius: 4px;
                        color: #565656;
                        padding: 5px 8px;
                        height: 30px;
                        box-shadow: none;
                        font-size: 12px;
                        margin: 5px 0px;
                        width: 97% !important;
                    }

                    div#reportrange {
                        background-color: #ffffff !important;
                        border-radius: 4px 4px 4px 4px;
                        color: #565656;
                        padding: 5px 7px !important;
                        height: 30px;
                        // width: 166px;
                        box-shadow: none;
                        font-size: 13px !important;
                        margin: 5px 0px 5px;
                        border-right: 0;
                        border-color: #e3e3e3 !important;
                    }

                    .form-control.borderradius {
                        height: 30px;
                        margin-top: 5px;
                        background-color: #ffffff !important;
                        border: 1px solid #e3e3e3 !important;
                        border-left: 0 !important;
                        border-radius: 0px 4px 4px 0px;

                        i {
                            font-size: 14px !important;
                            margin-top: -1px;
                            margin-left: -10px !important;
                        }
                    }
                }
            }
        }

        tbody {
            font-size: 14px !important;

            tr {
                td a {
                    font-size: 14px !important;
                }

                td {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    font-size: 14px !important;

                    // .text-danger.block.error-msg {
                    //     padding-top: 5px !important;
                    //     padding-bottom: 15px !important;
                    // }
                }
            }
        }
    }

    .pagination-container {
        padding: 0;
        padding-top: 5px;

        .rows-con {
            span {
                font-size: 13.5px !important;
                padding-right: 8px;
            }

            .MuiSelect-select {
                font-size: 13.5px !important;
                margin-top: 0px;
            }

            svg[class^="PageSizeSelector-se"] {
                top: 6px;
            }
        }

        .sub-pagination {
            span {
                font-size: 13px !important;
            }
        }
    }
}

.profile-picture {
  width: 40px; /* Adjust the size as per your requirement */
  height: 40px;
  border-radius: 50%; /* This creates the round shape */
  background-color: #e9e9e9;
  margin-bottom: -1%;
  background-image: url('../img/default-avatar.png');
  background-size: 40px 40px;
  }

.page-heading {
    position: relative;

    .btn-add-workorder {
        padding: 5px 10px;
        margin-bottom: 10px;
        margin-right: 13.5px;
        position: unset;
        top: 0;
        right: 0;
        font-size: 13px;
    }

    .header-title-master {
        display: inline-block;
        font-size: 14px;
        margin-top: 3px;
    }

    .header-title {
        font-size: 16px !important;
    }
}

.employee-grid-container {
    .MuiToolbar-regular {
        display: none;
    }

    .MuiTableCell-head {
        font-weight: 600;
    }
}

.employee-list-grid {
    margin: 0px 10px 0px 10px;
}

#mat-custom-Table {
    .MuiTableBody-root {
        .MuiTableRow-root {
            //height: 50px;

            .MuiTableCell-root {
                padding: 0px 5px;

                [class*="MUIDataTableBodyCell-stackedCommon-"] {
                    font-size: 13px !important;

                    .table-survey-button {
                        cursor: pointer;
                        //margin: 8px 3px;
                    }
                }
            }
        }
    }

    tr.MuiTableRow-root.MuiTableRow-head {
        th {
            height: 40px;
        }
    }

    .MuiTablePagination-root {
        .MuiTablePagination-toolbar {
            .MuiTablePagination-caption {
                font-size: 14px;
            }

            .MuiTablePagination-input {
                margin-top: 5px;

                .MuiSelect-root.MuiSelect-select {
                    font-size: 13px !important;
                }
            }
        }
    }

    [class*="MUIDataTableToolbar-actions-"] {
        .MuiButtonBase-root.MuiIconButton-root {
            padding: 10px !important;
        }

        .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
        }
    }

    .MuiPaper-root {
        padding: 10px 20px;
    }

    .MuiTableCell-head {
        font-size: 15px;
        font-weight: 700;
        padding: 8px;
        padding-top: 0px !important;
    }

    input.inline-search-number {
        display: block;
        padding: 7px 10px;
        width: 97% !important;
        margin-top: 4px !important;
        border: none;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        color: #565656;

        &::placeholder {
            color: #d3d3d3;
            font-weight: 600;
        }
    }

    th,
    td {
        &.MuiTableCell-root.MuiTableCell-alignLeft {
            padding: 5px;
        }

        button.edit-btn,
        button.btn-danger,
        button.btn-info,
        button.delete-btn {
            border-color: #ffffff00 !important;
            background-color: #ffffff00 !important;

            .table-survey-button {
                margin: 4px 3px;
            }
        }

        // .table-button-defaultHight {
        //     height: 36px;
        // }
    }

    tbody {
        tr {
            th {
                .mat-table-edit-button {
                    padding: 0;

                    .table-survey-button {
                        margin: 0px 7px;
                    }
                }
            }

            td {
                div {
                    font-weight: 500 !important;
                }
            }
        }
    }

    .no-box-shadow {
        box-shadow: unset;
    }

    .mat-inline-edit-Table {
        .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
            padding: 14px 8px;
        }
    }

    .custom-table-actions {
        text-align: right;
        // padding-right: 25px;
        padding-top: 8px;
        // background-color: #fff;
        margin-top: 8px;
        position: absolute;
        right: 80px;
        z-index: 9;
    

        .MuiButtonBase-custom-print {
            border: none;
            background: transparent;
            float: right;
            margin-right: 40px;
            opacity: 1;

            &.row {
                margin-top: -3px;
                margin-right: -5px;
                margin-left: 0px;
            }

            button {
                border: none;
                background: transparent;

                .fa {
                    font-size: 20px;
                    color: #505050;
                }
            }

            svg {
                height: auto;
                width: 25px;
            }
        }
    }

    // .datatables-noprint {
    //     width: 100%;
    // }
}

.MuiPopover-root {
    .MuiPaper-root.MuiPopover-paper[class*="MUIDataTableToolbar-filterPaper-"] {
        [class*="MUIDataTableFilter-root-"] {
            ul {
                li {
                    .MuiInputLabel-formControl {
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 500;

                        &.MuiInputLabel-shrink {
                            font-size: 14px !important;
                        }
                    }

                    .MuiInputBase-root {
                        font-size: 15px;
                        font-family: Arial;
                        font-weight: 500;
                    }
                }
            }
        }

        .MuiChip-label {
            font-size: 12px;
        }
    }

    .MuiList-root.MuiMenu-list.MuiList-padding {
        .MuiListItemText-root {
            .MuiTypography-root {
                font-size: 14px;
                font-family: Arial;
                font-weight: 500;
            }
        }
    }
}


@media print {

    #custom-table-actions,
    .custom-table-actions,
    .disable-print {
        display: none;
    }

    tfoot[class^="MuiTableFooter-root"] {
        display: none;
    }

    #MUIDataTableCustomised,
    .summary-footer {
        display: block;
    }

    #MUIDataTableCustomised {
        table.MuiTable-root {
            display: block;
        }

        [class^="MUIDataTable-responsiveBase"] {
            table.MuiTable-root {
                display: table;
            }
        }
    }
}

#MUIDataTableCustomised {
    .MuiPaper-root {
        box-shadow: unset;
    }
}

/* #MUIDataTableCustomised {
    table.MuiTable-root {
        display: block;
    }

    [class^="MUIDataTable-responsiveBase"] {
        table.MuiTable-root {
            display: table;
        }
    }
} */

.mat-collaps-table-container {
    text-align: right;

    .collaps-heading-value {
        font-size: 14px;
        color: #000;
    }

    .summary-tr {
        .summary-td {
            font-size: 15px !important;
            font-weight: bold;
        }
    }
}

.popup-filter-datepicker {
    td.MuiTableCell-root {
        padding: 0 !important;
        width: 220px;

        #reportrange {
            span {
                padding-top: 2px;
                padding-left: 5px;
                font-size: 12px;
            }

            .fa-calendar-alt {
                padding-top: 4px;
            }
        }
    }
}

.resource-productivity-list-container {
    .resource-productivity-list-table {
        .card {
            padding: 15px;

            .MuiTableCell-sizeSmall {
                padding: 7px 10px 8px 23px;
            }
        }
    }
}

.emp-dropdown-container {
    .emp-dropdown-list {
        margin-bottom: 5px;
    }

    .emp-dropdown-button {
        height: 35px;
        margin-top: 2px;
        display: flex;
    }
}

.add-employee-container {
    margin-top: 15px;
    text-align: center;
}

.form-control.error-input {
    border: 1px solid #ff0000 !important;
}

.status_class_btn {
    margin-bottom: -9px;
}

.workOrderVerify-footer-buttons {
    button {
        margin-right: 15px;
    }
}

.mb-20 {
    margin-bottom: 20px !important;
}

.pd-20 {
    padding: 20px;
}

.pd-20r {
    padding: 20px 20px 0px 20px;
}

.pdr-1 {
    padding-right: 5px;
}

@-moz-document url-prefix() {
    select.form-control {
        padding-right: 25px;
        background-image: url("data:image/svg+xml,\
        <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'\
             height='14px' viewBox='0 0 1200 1000' fill='rgb(51,51,51)'>\
          <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/>\
        </svg>");
        background-repeat: no-repeat;
        background-position: calc(100% - 7px) 50%;
        -moz-appearance: none;
        appearance: none;
        text-align: left !important;
    }
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 8px !important;
    display: none;
}

.react-datepicker__year-option--selected_year {
    background-color: #aaa !important;
}

.pull-right-position {
    top: 6px;
}

.table-loader {
    display: inline-block;
    position: absolute;
    width: 55px;
    height: 55px;
    overflow: visible;
    margin: auto;
    top: 15px;
    left: 0px;
    bottom: 0;
    right: 30px;
}

.table-loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 27px;
    height: 27px;
    margin: 6px;
    border: 2px solid #5592fb;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #5592fb transparent transparent transparent;
}

.table-loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.table-loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.table-loader div:nth-child(3) {
    animation-delay: -0.15s;
}

.setcenter {
    position: relative;
    text-align: center;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-ring {
    // display: inline-block;
    // position: fixed;
    // width: 64px;
    // height: 64px;
    // overflow: visible;
    // margin: auto;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    // z-index: 100000;
    display: inline-block;
    position: fixed;
    width: 64px;
    height: 64px;
    overflow: visible;
    margin: auto;
    top: 300px;
    left: 0;
    /* bottom: 0; */
    right: 0;
    z-index: 100000;
}

.lds-ring:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.12);
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 4px solid #5592fb;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #5592fb transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.email_template_holder table tbody tr:first-child h1 {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
}

.email_template_holder table tbody tr:nth-child(2) h2 {
    margin-top: 0px;
}

.header-title {
    font-size: 16px;
}

#main-panel .container-fluid .dashboard-user-lable span {
    float: right;
    margin-right: 90px;
}

@media (max-width: 480px) {
    #main-panel .container-fluid .dashboard-user-lable {
        top: 20px !important;
    }

    #main-panel .container-fluid .dashboard-user-lable span {
        float: right;
        margin-right: 56px;
    }
}

#workOrderGroupContainer .googlegraph {
    overflow: hidden !important;
    width: 425px !important;
}

.print-header-nowrap table thead tr th {
    white-space: nowrap;
}

.reportDealer-totalBold div[class^="MuiPaper"] div[class^="TableSummaryItemBase"],
.reportDealer-totalBold table[class^="jss"] tbody tr td:nth-child(3) div[class^="jss"] {
    font-weight: bold;
    font-size: 14px !important;
}

.showLoader {
    display: inline-block !important;
}

.main-panel>.content {
    padding: 30px 15px;
    min-height: calc(100vh - 123px) !important;
}

.table-approve-button,
.tverify-button {
    cursor: pointer;
}

.workorder-details-modal {
    .location-dropdown {
        display: block;
        position: relative;
        z-index: 999;
    }
}

.table-survey-button {
    cursor: pointer;
    margin: 10px 3px;
}

.validating-request {
    width: 100vw;
    text-align: center;

    div {
        display: inline-block;
        width: 100%;
    }
}

div[class^="MuiTooltip"] {
    font-size: 14px !important;
}

div[id^="mui-tooltip"] {
    font-size: 14px !important;
}

div[role="tooltip"] {
    font-size: 14px !important;
}

div[class^="TableSummaryItemBase"] {
    font-size: 14px !important;
}

/*------------------- Common Modal----------------*/
.common-modal {
    .common-modal-body {
        padding-bottom: 0px;

        .workorder-details-modal {
            min-width: 560px;

            .form-group {
                .col-form-label {
                    padding-left: 10px;
                }
            }

            .row {
                position: relative;
            }

            .segment-container {
                text-align: center;
                position: absolute;
                height: 20px;
                top: 52px;
                left: 0;
                right: 0;
                z-index: 99;

                .segment-button {
                    background-color: white;
                    display: inline-block;
                    padding: 5px 16px;
                    font-size: 14px;
                    border-radius: 3px;
                    color: black;
                    border: 2px solid #ddd;
                    font-weight: 100;
                }
            }

            .workorder-modal-container {
                margin: 5px 25px;
                border: 2px solid #ddd;
                padding: 25px;
                border-radius: 8px;
                position: relative;
                margin-top: 15px;
                min-height: 290px;
                max-height: 400px;
                overflow: scroll;

                .col-md-2 {
                    margin: 8px 6px;
                    position: relative;

                    &:after {
                        content: "";
                        /* border-bottom: 2px solid #ddd; */
                        position: absolute;
                        bottom: -5px;
                        left: -2px;
                        right: -10px;
                    }

                    div {
                        margin: 5px 0px;
                        color: #4689d2;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .common-modal-footer {
        flex-direction: column;

        button {
            font-size: 14px;
        }
    }
}

.filter-container {
    float: right;
    margin-right: 5%;
    border-bottom: 1px solid #ccc;

    input.filter-box {
        border: unset;
        width: 100%;
        padding-left: 10px;
        padding-right: 20px;
    }

    img,
    .fa {
        padding: 3px;
        height: 22px;
        position: absolute;
        left: 0;
        font-size: 15px;
        color: #a7a7a7;
    }

    .fa-times {
        right: 0;
        cursor: pointer;
        left: unset;
    }
}

.splitPO-modal {
    .segment-container {
        font-size: 15px;

        .segment-button {
            textarea.form-control {
                min-height: 180px;
            }

            .mt-10 {
                margin-top: 10px;
            }
        }
    }
}

/*-------------------Purchase order views----------------*/
.btn-quickSand {
    background-color: #8493ca !important;
    border-color: unset !important;
    border-radius: 5px;

    &:focus,
    &:active {
        background-color: #8493ca !important;
    }
}

.purchaseOrderView {
    .purchaseOrder {
        .received-items-btn-container {
            text-align: right;

            .received-items-btn {
                background-color: #8493ca;
                border-color: transparent;
                border-radius: 5px;

                &:focus,
                &:active {
                    background-color: #8493ca;
                }
            }
        }

        .table {
            td {
                .inline-editable {
                    width: 70px;
                    border-radius: 4px;
                    border: 1px solid #ccc;
                    padding: 10px 0px 5px 5px;
                    margin-right: 4px;

                    &.error-input {
                        border: 1px solid #f00;
                    }

                    &.warning-input {
                        border: 1px solid #e3550f !important;
                    }
                }

                .inline-editable-select {
                    div {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }

    .verifycomplete-items-btns-container {
        text-align: center;

        .received-items-btn {
            background-color: #8493ca !important;
            border-color: transparent;
            border-radius: 5px;
        }
    }

    span.error-minum-order {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 15px;
        color: red;
    }
}

.btn {
    padding: 7px 15px !important;
}

.custom-link {
    color: #3472f7;
    cursor: pointer;
}

.verifyCompletedConfirmContainer {
    .verify-confirm-modal-container {
        padding-left: 30px;
        margin-bottom: 6px;

        .verifyCompletedConfirm {
            display: block;
            margin: 3px 0px;
            font-size: 15px;

            span {
                padding-right: 10px;
            }
        }
    }

    .supplyItemModalContent {
        font-size: 16px !important;
    }
}

.VerifyConfirmModalAction {
    button {
        margin: 5px 7px;
    }
}

/*-------------------CheckBox----------------*/
/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.checkmark {
            background-color: #2196f3;
        }

        &:checked~.checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover input~.checkmark {
        background-color: #ccc;
    }
}

.service_capture {
    .back-button {
        margin-top: 3px;
    }
}

@media (max-width: 1024px) {
    .service_capture {
        .Capture-Service {
            .col-md-4 {
                margin-top: 10px;
            }
        }
    }
}

/*-------------------Headrer Short----------------*/
.sidebar .logo {
    padding: 6px 30px;
}

.navbar-nav>li>a {
    line-height: 14px;
}

.navbar .navbar-nav>li>a {
    margin: 7px 3px;
}

.navbar .navbar-brand {
    margin: 1px 0px;
}

.edit-dealer-info {
    margin-right: 14px;
}

.edit-dealer-info label {
    font-size: 16px !important;
}

#main-panel .pt-8px {
    padding-top: 8px !important;
}

// #main-panel .pt-5px {
//     padding-top: 5px !important;
// }

#main-panel .pt-10px {
    padding-top: 10px !important;
}

.mb-12px {
    margin-bottom: 12px;
}

.survey_view_content .col-md-6 .form-group {
    margin-left: 0px !important;
}

.clearfix-both {
    clear: both;
}

.nav.navbar-nav.navbar-right span i.fa-sign-out-alt {
    color: #c92f2b !important;
}

.cl-dashboard-filter #reportrange {
    padding-left: 8px !important;
}

/*---------------------SCROLLBAR SIDEMENU----------------*/
div.sidebar-wrapper::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

div.sidebar-wrapper {
    scrollbar-width: none;
}

.editWo-inputWidth {
    td & {
        width: 75px !important;
    }
}

div.sidebar-wrapper::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 50px;
}

@page {
    size: auto;
    margin: 0mm;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
    min-width: 20px !important;
    width: 33px !important;
    height: 24px !important;
    font-size: 13px !important;
}

/*----------------------Dashboard css---------------------*/

.backlogtablediv div div div:nth-child(3) div span,
.backlogtablediv div div div:nth-child(1) div,
.pendingtable div div div:nth-child(3) div span,
.tablediv div div div:nth-child(1) div,
.pendingtable div div div:nth-child(1) div {
    font-size: 14px !important;
    color: #333;
}

.card .footer {
    display: none;
}

.card-body svg g {
    cursor: pointer;
}

.graphdiv svg {
    width: auto !important;
}

.headingcustom {
    color: "#858585" !important;
    font-weight: bold;
}

.pendingtable table tbody tr td:nth-child(1) {
    padding-left: 10px;
}

.recenttablediv table {
    padding: 0px 10px 10px;
}

.backlogtablediv table tbody tr td:nth-child(1) {
    padding-left: 10px;
}

.dashboard-filter .col-lg-2 {
    width: 22%;
}

.dashboard-filter {
    margin-top: 10px;
}

.extend {
    background: #fff !important;
    cursor: pointer !important;
    border: 1px solid #ccc !important;
}

.react-bootstrap-daterangepicker-container {
    width: 100%;
    cursor: pointer !important;
}

.react-bootstrap-daterangepicker-container #reportrange i.fa-calendar {
    padding-right: 5px;
}

@media (max-width: 1024px) and (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .countdiv .col-lg-6 .card-body .col-md-4 {
        width: 30%;
        float: left;
    }

    .countdiv .col-lg-6 .card-body .col-md-8 {
        width: 70%;
        float: left;
    }

    .countdiv .col-lg-3 .card-body .col-md-4 {
        width: 30%;
        float: left;
    }

    .countdiv .col-lg-3 .card-body .col-md-8 {
        width: 70%;
        float: left;
    }

    .dashboard-filter .col-lg-2 {
        width: 77%;
    }

    .dashboard-filter .col-lg-3 {
        width: 77%;
    }
}

.countdiv .col-md-4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fontLabel {
    font-size: 16px !important;
}

svg>g>g {
    pointer-events: none;
    cursor: unset;
}

.pendingtable table thead tr th:nth-child(3),
.pendingtable table thead tr th:nth-child(4) {
    padding-left: 8px;
    padding-right: 8px;
}

.backlogtablediv table thead tr th:nth-child(5),
.backlogtablediv table thead tr th:nth-child(7) {
    padding-left: 8px;
    padding-right: 8px;
}

@media (min-width: 1200px) {
    .countdiv .col-lg-3 {
        width: 20%;
    }
}

@media (min-width: 320px) {
    .countdiv .col-lg-3 {
        margin-bottom: 10px;
    }
}

@media (min-width: 1200px) {
    .claim_Count_div .col-lg-3 {
        width: 25%;
    }
}

@media (min-width: 320px) {
    .claim_Count_div .col-lg-3 {
        margin-bottom: 10px;
    }
}

// @media (min-width: 1200px){
//     .claim_Count_div .col-lg-6 {
//         width: 36%;
//     }
// }

table thead tr:first-child {
    height: 42px;
}

/*----------------------Work Order View---------------------*/

.workOrderTable table thead tr th:nth-child(5),
.workOrderTable table thead tr th:nth-child(4),
.workOrderTable table thead tr th:nth-child(3) {
    padding-left: 8px;
    padding-right: 8px;
}

.workOrderTable div div div:nth-child(3) div span,
.workOrderTable div div div:nth-child(1) div {
    font-size: 14px !important;
    color: #333;
}

.dotAreaManagerApproved {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #8682bf;
}

.dotSubmitted {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #3fbb7c;
}

.dotRejected {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #fcb163;
}

.dotVerified {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #01bff5;
}

.dotWHApproved {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #ee0f85;
}

.dotDraft {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: yellow;
}

.dotReadyForDelivery {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #177bf6;
}

.dotReadyForPartial {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: lightpink;
}

.fontLabel {
    font-size: 16px !important;
}

.setcenter {
    text-align: center !important;
}

/*----------------------Edit Vendor View---------------------*/
.css-kj6f9i-menu {
    margin-top: 0 !important;
}

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .Vendor_Edit.card-body .row .col-md-6 .form-group {
        margin-left: 0% !important;
    }
}

/*----------------------Dealer View---------------------*/

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {

    .dealer_view.card-body .row .col-md-6 .form-group,
    .Vendor_Add.card-body .row .col-md-6 .form-group,
    .card-body .Dealer_Edit .col-md-6 .form-group {
        margin-left: 0% !important;
    }
}

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .Dealer_Add.card-body .row .col-md-6 .form-group {
        margin-left: 0% !important;
    }
}

/*----------------------Order for delivery View---------------------*/

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1020px) {
    .Reportdealer-filter .col-lg-3 {
        width: 77%;
    }
}

.disabled_scroll {
    overflow: hidden;
}

/*----------------------Report Area Dealer View---------------------*/
.reportarea-filter {
    margin-top: 10px;
    margin-left: 253px;
}

.Reportdealer-filter .col-lg-4 {
    width: 30%;
}

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .reportarea-filter .col-lg-4 {
        width: 77%;
    }

    .reportarea-filter {
        margin-left: 0px !important;
    }

    /* .Reportdealer-filter .col-lg-3{
      width: 77%;
    } */
}

@media print {
    .chamicalCostContainer {
        page-break-before: avoid;
        page-break-after: avoid;
    }

    .purchaseOrderView {
        .table>thead:first-child>tr:first-child>th {
            font-size: 13px !important;
        }

        .table>thead:first-child>tr:first-child>td {
            font-size: 13px !important;
        }
    }
}

table tfoot tr td:nth-child(3) div {
    font-size: 16px !important;
    font-weight: bold !important;
}

/*----------------------Global Table View---------------------*/

#productTable {
    #commonTable {
        button.transparent {
            border: unset;
            background: transparent;
        }
    }
}

/*----------------------Product List View---------------------*/
.t1 {
    font-size: 15px;
}

.Pagination-pagination {
    float: left;
    margin: 0;
}

.productTable {
    margin-top: 10px !important;
}

/*----------------------Vendor View---------------------*/
.card label {
    font-weight: bold !important;
    color: #444 !important;
    font-size: 14px !important;
    text-transform: unset !important;
}

.card .control-label {
    font-weight: normal !important;
    font-size: 14px !important;
}

.back-button {
    display: flex;
    justify-content: center;
}

.btn-back {
    padding: 6px 25px !important;
    background-color: #286090 !important;
    border-color: #286090 !important;
    opacity: 1 !important;
}

.card-body .row .col-md-6:first-child .form-group {
    margin-left: 70px;
}

.card-body .row .col-md-6:nth-child(2) .form-group {
    margin-left: 40px;
}

.card-body .row .col-md-6 .form-group {
    margin-bottom: 10px;
}

.header-title {
    font-weight: 600;
    font-size: 16px;
    padding-left: 2px;
}

.header-title-master {
    font-weight: 600;
    display: inline-block;
    color: #8a8a8a;
    margin-bottom: 0;
    font-size: 14px;
    cursor: pointer;
}

.main-panel>.content {
    padding-top: 15px !important;
}

.page-heading {
    margin-bottom: 0px;
    padding-left: 1px;
}

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .Vendor_View.card-body .row .col-md-6 .form-group {
        margin-left: 0% !important;
    }
}

/*----------------------WorkOrder View---------------------*/

@media print {
    .mainprint .colleft {
        width: 500px;
        color: brown;
    }

    .tablediv,
    .card,
    .cardbody,
    .table-responsive,
    .table,
    body,
    tbody,
    tfoot {
        overflow: hidden;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }

    #main-panel {
        height: 90% !important;
    }

    #react-print,
    .content {
        page-break-after: avoid;
        page-break-before: avoid;
    }

    .padding-custom-15px {
        padding: 0 !important;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .catagory-list {
        margin-bottom: 60px;
    }

    /* .category-table-header{
        margin-bottom: 10px!important;
    } */
    .vendor-vise-tables {
        overflow: hidden !important;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        height: auto;
    }

    .no-maxHeight-Print {
        max-height: unset !important;
    }

    h4.no-space {
        margin-top: 0 !important;
    }

    .hr-margin12 {
        margin: 0;
    }

    .card {
        margin-bottom: 0px;
    }

    .width-122px {
        white-space: nowrap !important;
        /* width: 50%!important; */
    }

    .width-122px b {
        font-size: 8px !important;
    }

    .leftside-label.first-label {
        white-space: nowrap !important;
    }
}

.btn-back-wov {
    float: right;
    margin-right: 10px;
    /* margin-right: -301px; */
    padding: 6px 25px !important;
    background-color: #b9b9b9 !important;
    border-color: #b9b9b9 !important;
    opacity: 1 !important;
}

.app-btn {
    margin-right: 10px;
}

.div-btn {
    display: flex;
    justify-content: center;
}

.view-btn-center {
    width: 100% !important;
    text-align: center !important;
}

.fontLabel {
    font-size: 16px !important;
}

.workorder>.table>thead>tr>th {
    color: #747474;
    font-weight: bold;
}

.btn-verify-btn,
.btn-reject-btn {
    margin-right: 10px;
}

/*----------------------Work Order Add---------------------*/
.workordernew .card label.control-label {
    font-weight: bold !important;
}

.workordernew .productbox {
    background-color: #eee;
    margin: 5px 0;
    padding: 5px 0 0 0;
}

.workordernew .quantity {
    width: 65px;
}

.btn-circle {
    padding: 5px !important;
    border-radius: 21px !important;
}

.workordernew .textmiddle {
    vertical-align: middle !important;
    padding: 0 !important;
}

.page-title {
    color: #777;
    font-size: 11px;
}

.page-subtitle {
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 20px;
}

.hr-margin12 {
    margin: 12px 0px;
}

.workorder-top-details .row {
    margin-bottom: 10px;
}

.amount-col {
    text-align: right;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}

.tfoot-view-wo {
    font-size: 14px;
    border-top: none;
    font-weight: bold !important;
}

.tfoot-view-wo td {
    border-top: none !important;
    font-weight: bold !important;
}

/*---------------------- Report Area ---------------------*/

table tfoot tr td div,
table tbody tr td div {
    font-size: 14px !important;
}

.reportarea-filter {
    margin-top: 10px;
    margin-left: 253px;
}

.Reportdealer-filter .col-lg-4 {
    width: 30%;
}

@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .reportarea-filter .col-lg-4 {
        width: 77%;
    }

    .reportarea-filter {
        margin-left: 0px !important;
    }

    /* .Reportdealer-filter .col-lg-3{
      width: 77%;
    } */
}

@media print {
    .chamicalCostContainer {
        page-break-before: avoid;
        page-break-after: avoid;
    }
}

table tfoot tr td:nth-child(3) div {
    font-size: 16px !important;
    font-weight: bold !important;
}

body>#root>div {
    height: 100vh;
}

/*----------------------Product Edit---------------------*/
@media (max-width: 420px) {

    // .productEditScreen {
    .page-heading {
        .header-title-master {
            font-size: 13px;
        }

        .header-title {
            font-size: 14px;
        }

        .btn-add-workorder {
            font-size: 11px;
            padding: 4px 9px;
        }
    }

    // }
}

/*----------------------End---------------------*/

@media (max-width: 500px) {
    .col-12 {
        width: 100%;
    }
}

.menu-loader {
    width: 100%;
    text-align: center;

    img {
        height: 59px;
        width: auto;
    }
}

.username-loader {
    height: 100px;
    position: absolute;
    top: -20px;
    right: 85px;
}

.title-loader {
    height: 100px;
    position: absolute;
    top: -20px;
    left: 16px;
}

/* ---------Loader designs ----------- */

@media (max-width: 460px) {
    .xs-overflow-auto {
        overflow: auto;
    }
}

@media (max-width: 1199px) {
    #workOrderGroupContainer .googlegraph {
        overflow: auto;
    }

    .cl-dashboard-filter .apply-btn {
        margin-top: 10px !important;
    }
}

@media (max-width: 700px) {
    table .setcenter {
        text-align: left !important;
        left: 20%;
    }

    table .table-loader {
        width: unset;
        left: 0%;
    }
}

@media (max-width: 992px) {
    .common-modal {
        .common-modal-body {
            .workorder-details-modal {
                min-width: auto;

                .segment-container {
                    top: 85px !important;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .reportDealerShipContainer .desktop-d-flex .left-container {
        margin-top: 10px;
        margin-right: 0;
    }

    #workOrderGroupContainer {
        max-height: 380px;
    }

    .common-modal {
        .common-modal-body {
            .workorder-details-modal {
                min-width: auto;
            }
        }
    }
}

@media (max-width: 600px) {
    .edit-dealer-info {
        margin-right: 0px;
        padding-left: 13px;
        width: 100%;
        display: block;
    }

    table .table-loader {
        width: unset;
        left: 0%;
    }

    table.product-edit tbody tr td:nth-child(2) div {
        width: 162px;
    }

    table.product-edit tbody tr td:nth-child(3) div {
        width: 100px;
    }

    table.product-edit tbody tr td:nth-child(4) div.react-datepicker-wrapper {
        width: 150px;
    }
}

@media (max-width: 480px) {
    .history-btn-product {
        margin-left: 0px !important;
        margin-top: 5px !important;
    }

    table .setcenter {
        text-align: left !important;
        left: 10% !important;
    }

    .dashboard-container #workOrderGroupContainer {
        overflow: auto !important;
    }

    .xs-pr-0 {
        padding-right: 0px;
    }
}

@media (max-width: 400px) {
    .col-12-xs {
        width: 100%;
    }

    table .table-loader {
        width: unset;
        left: 0%;
    }
}

.add-new-survey,
.add-new-dealer {
    padding: 5px 10px !important;
    font-size: 13px;
}

.reports {
    margin-bottom: 16px;
}

.clear-both {
    clear: both;
}

.card .Reportdealer-filter label {
    font-weight: 500 !important;
    font-size: 14.5px !important;
}

.reportarea-filter {
    margin-left: 0px !important;
}

.reportarea-filter label {
    font-weight: 500 !important;
    font-size: 14.5px !important;
}

@media (min-width: 992px) {
    .Reportdealer-filter .col-lg-3 {
        width: 29%;
        z-index: 59;
    }

    .Reportdealer-filter .col-lg-2 {
        width: 25%;
    }
}

@media (max-width: 992px) {
    .Reportdealer-filter .col-lg-3 {
        width: 49%;
    }

    .Reportdealer-filter .col-lg-2 {
        width: 49%;
    }

    .responsive-card-container .row .card .card-body .Reportdealer-filter .apply-btn {
        margin-top: 21px !important;
    }
}

@media (max-width: 767px) {
    .Reportdealer-filter .col-lg-3 {
        width: 90%;
    }

    .Reportdealer-filter .col-lg-2 {
        width: 90%;
    }

    div.responsive-card-container .row .card .card-body .Reportdealer-filter .apply-btn {
        margin-top: 0px !important;
    }
}

.react-bootstrap-table-pagination #pageDropDown {
    margin-top: 15px;
}

.survey_view_content .form-group div {
    color: #444444;
}

#dealershipreporttable div table thead tr th div span {
    font-size: 14px !important;
}

.card {
    margin-top: 5px;
}

.content.reportDealerShipContainer {
    padding-top: 0px !important;
}

.mb-3px {
    margin-bottom: 3px;
}

textarea {
    max-width: 100%;
}

.align-items-center h5 {
    margin-top: 0px !important;
}

.sv_main .sv_container .sv_body {
    padding: 1em 1em 2em 1em !important;
}

.clone-survey {
    margin-top: 7px !important;
}

.clone-survey-hr {
    margin-top: 0px !important;
    margin-bottom: 12px;
}

.sidebar-title {
    display: block;
}

.sidebar .logo {
    padding: 8px 30px !important;
}

.logo .logo-mini {
    padding-top: 10px;
}

.sidebar .logo .simple-text {
    font-size: 14px !important;
    line-height: 1.3 !important;
    font-weight: 600 !important;
}

.product-edit tbody tr .react-datepicker-popper {
    margin-top: 8px;
}

.card-body-edit,
.card-body-add {
    padding: 0px !important;
}

.POtable button i.fa-trash-alt,
.POtable button i.fa-edit {
    font-size: 19px;
}

.POtable button i.fa-trash-alt {
    color: #c42f2b;
}

.POtable button i.fa-edit {
    color: #2060bf;
}

.POtable .save-icn-PO {
    background: #089653;
    border-radius: 20px;
    padding: 4px;
}

.POtable .save-icn-PO svg {
    font-size: 15px !important;
    color: #fff;
}

.POtable button.btn-danger {
    background-color: unset !important;
    border: none;
}

.float-left {
    float: left;
}

//  ***----------------- Edit Product -----------****

fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

// ************** Edit Vendor Price *****************
.t1 {
    font-size: 15px;
}

// *************** LOGIN ******************
.session-timeout h1 {
    margin: 0;
    padding: 0;
}

.session-timeout h1 {
    line-height: 1.5;
    font-weight: inherit;
}

.session-timeout h1 {
    font-family: "Poppins", sans-serif;
}

.session-timeout p {
    line-height: 1.6;
    font-size: 1.05em;
    font-weight: 400;
    color: #555;
}

.session-timeout h1 {
    font-size: 3.5em;
    line-height: 1;
}

.session-timeout img {
    width: 100%;
}

.session-timeout li {
    list-style: none;
    display: inline-block;
}

/* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

p.desc {
    font-family: "Poppins", sans-serif;
}

.get-back {
    padding: 10px 20px;
    border-radius: 5px;
    background: #493989;
    border: none;
    color: #fff;
    cursor: pointer;
}

.center-text {
    text-align: center;
}

.session-timeout .display-table {
    display: table;
    height: 100%;
    width: 100%;
}

.session-timeout .display-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.main-area {
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 0 20px;
    background-size: cover;
    box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.3);
    color: #fff;
}

.main-area:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0.7;
    background: linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%);
}

.main-area .desc {
    margin: 20px auto;
    max-width: 500px;
    color: #fff;
}

.login_input {
    height: 40px;
    border-radius: 5px;
    border: none;
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
    color: #000;
}

.login_holder {
    width: 300px;
    text-align: left;
    height: 380px;
    margin: auto;
    padding: 20px;
    border: 1px solid #f2f2f299;
    border-radius: 5px;
    background-color: #ffffff3d;
}

.error_login {
    color: #e40303;
    border-radius: 5px;
    border: 1px solid #ffff;
    background-color: #ffffffab;
    text-align: center;
    margin-bottom: 5px;
}

// ************ Workorder Add **************

.workordernew .card label.control-label {
    font-weight: bold !important;
}

.workordernew .productbox {
    background-color: #eee;
    margin: 5px 0;
    padding: 5px 0 0 0;
}

.workordernew .quantity {
    width: 65px;
}

.btn-circle {
    padding: 5px !important;
    border-radius: 21px !important;
}

.workordernew .textmiddle {
    vertical-align: middle !important;
    padding: 0 !important;
}

.page-title {
    color: #777;
    font-size: 11px;
}

.page-subtitle {
    color: #777;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.hr-margin12 {
    margin: 12px 0px;
}

.workorder-top-details .row {
    margin-bottom: 10px;
}

.amount-col {
    text-align: right;
}

.tfoot-view-wo {
    font-size: 14px;
    border-top: none;
    font-weight: bold !important;
}

.tfoot-view-wo td {
    border-top: none !important;
    font-weight: bold !important;
}

.delete-btn-product,
.history-btn-product {
    font-size: 11.5px !important;
}

.table-verify-button {
    cursor: pointer;
}

.error-msg-order-container {
    position: relative;

    span.error-msg-order-con {
        position: relative;

        // &.error {
        //     input {
        //         border-color: red !important;
        //     }
        // }

        span.error-msg-order.checkbox {
            position: absolute;
            width: 132px;
            left: -1px;
            bottom: -21px;
            font-size: 10px;
        }

        span.error-msg-order {
            // position: absolute;
            // width: 132px;
            // left: -11px;
            // bottom: -15px;
            // font-size: 10px;
            // color: red;
            // font-size: 12px;
            // display: block;
            // padding: 5px 0px 0;
            position: absolute;
            width: 132px;
            left: -11px;
            bottom: -21px;
            font-size: 10px;

            &.warning {
                position: absolute;
                width: 166px;
                left: -13px;
                bottom: -12px;
                font-size: 10px;
                text-align: center;
                line-height: 12px;
                color: #ff9500 !important;
                // font-weight: bold;
            }
        }

        span.error-msg-select {
            position: absolute;
            width: 300px;
            left: 0;
            font-size: 10px;
            bottom: -15px;
        }
    }
}

td.test:after {
    content: "Danger mose fdsnoidf zXhdfkjdfsoi";
    position: absolute;
    bottom: 1px;
    color: red;
    left: -23px;
    width: 420px;
    font-size: 11px;
}

.delete-btn-product .fa,
.history-btn-product .fa {
    margin-bottom: 2px;
}

table div #reportrange {
    font-weight: 500 !important;
}

.cl-dashboard-filter #reportrange .fa-calendar-alt {
    top: 10px;
}

.fa-calendar-alt {
    font-size: 16px;
}

.Reportdealer-filter #reportrange {
    color: #565656;
}

@media (max-width: 600px) {
    .printbtn-hidden {
        display: none !important;
    }
}

@media (max-width: 968px) {
    .tab-and-mobile-hidden {
        display: none !important;
    }
}

.verify-button {
    background-color: #006b38 !important;
    color: #fff !important;
    border-color: #006b38 !important;
    border-radius: 4px !important;
}

#purchaseordertable {
    div table thead tr th div span div {
        table {
            thead {
                tr {
                    th {
                        div {
                            span {
                                font-size: 14px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .pagination-container {
        padding: 0;
        padding-top: 5px;

        .rows-con {
            span {
                font-size: 13.5px !important;
                padding-right: 8px;
            }

            .MuiSelect-select {
                font-size: 13.5px !important;
                margin-top: 0px;
            }

            svg[class^="PageSizeSelector-se"] {
                top: 6px;
            }
        }

        .sub-pagination {
            span {
                font-size: 13px !important;
            }
        }
    }
}

.btn-generatepo {
    background-color: #8682bf !important;
    border-color: #8682bf !important;
}

// dot for Purchase order

.dotPOApproved {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #3fbb7c;
}

.dotPODraft {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #fcb163;
}

.dotPOSubmitted {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #01bff5;
}

.dotPORejected {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #ee0f85;
}

.dotPOReadyfordelivery {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #8682bf;
}

@media (max-width: 500px) {
    .poweeklabel {
        text-align: center;
        width: 100%;
        padding-right: 15px !important;
    }

    .poweekButton {
        text-align: center;
        margin-top: 10px;
    }
}

.poweeklabel {
    padding-right: 0px;
}

#customers {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
}

#customers tbody td,
#customers th {
    padding: 8px;
}

#customers tfoot td,
#customers th {
    padding: 8px;
    font-size: 14px;
}

#customers tbody {
    border-bottom: 1px solid #d3d3d3;
}

#customers tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #d3d3d3;
    color: black;
}

#deatil_card {
    border: 1px solid #d3d3d3;
    margin: 5px;
    border-radius: 3px;
    padding: 4px;
}

div[class^="css-1s"] {
    border-color: unset !important;
    box-shadow: none !important;
}

/*----------------user update details modal-----------------*/

.user-update-details-modal {
    .user-update-details-modal-content {
        width: 500px;

        form {
            margin: 0;

            .form-control {
                margin: 0px 0px 10px;
            }
        }
    }

    div#alert-dialog-title h2 {
        font-weight: bold;
        padding-bottom: 13px;
        border-bottom: 1px solid #ccc;
    }

    .custom-modal-footer {
        justify-content: center;
        padding-bottom: 10px;

        .btn-success.btn-fill {
            color: #ffffff !important;
            background-color: #87cb16 !important;
            opacity: 1 !important;
            filter: alpha(opacity=100) !important;
        }
    }
}

.btn-table-transparent {
    border: none;
    padding: 0 !important;
    width: 35px;

    &.btn-primary:active:focus {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    // left: 18px;
    // top: 40px;
    // min-width: 360.75px;
    // border-radius: 3px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
    // background: rgba(255, 255, 255, 0.9);
    // padding: 2px 0px;
    // font-size: 90%;
    // overflow: auto;
    // max-height: 300px;
    // z-index: 998;
    // position: absolute;
}

.page-global-container {
    .filter-container {
        border-bottom: none !important;
        position: relative;
        margin-right: 0%;

        .fa-search {
            padding: 3px;
            height: 24px;
            position: absolute;
            left: 25px;
            color: #a7a7a7;
            top: 10px;
            font-size: 14px;
        }

        .filter-box {
            background: transparent;
        }

        .search-custom-input {
            padding-left: 36px;
        }
    }
}

#uncontrolled-tab-example {
    .nav.nav-tabs {
        border-bottom: unset;
        padding-left: 15px;
    }

    li {
        // background: #b3b3b3;
        // border-right: 1px solid #ddd;
        background: #4078a0;
        border-right: 1px solid #ecf1f3;

        a {
            color: #fff;
        }
    }

    .active {
        a {
            color: #000;
            margin-right: 0px;
            border-radius: unset;
        }
    }

    font-size: 13px;
}

/*----------------ErrorMsg-----------------*/
span.error-msg,
.error-msg span {
    position: absolute;
    width: 300px;
}

.inline-date-picker-name {
    padding-left: 0px !important;
    width: 130px;
    padding-right: 0;
}

.workordernew {
    .table-responsive.purchaseOrder {
        max-height: 635px;
        overflow: hidden;
        overflow-y: scroll;
        margin-bottom: 10px;
    }
}

@media print {
    .posummary table {
        page-break-inside: auto;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        table-layout: auto !important;
    }

    .posummary tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    .posummary tfoot {
        display: table-footer-group;
    }

    .posummary th,
    .posummary td {
        padding: 4px 4px !important;
    }

    .posummary tfoot th,
    .posummary tfoot td {
        padding: 0px !important;
        white-space: unset;
        display: table-cell;
    }

    .posummary svg {
        display: none;
    }

    .main-panel {
        width: 100% !important;
    }

    .workordernew {
        .table-responsive.purchaseOrder {
            max-height: inherit !important;
            overflow: unset !important;
        }
    }
}

.Wo-Edit .hr-margin12 {
    margin: 12px 0px 0px 0px !important;
}

// .text-center {
//     button {
//         &:hover{
//             background-color: red !important;
//         }
//     }
// }

.backlogtablediv div div div:nth-child(3) div span,
.backlogtablediv div div div:nth-child(1) div,
.pendingtable div div div:nth-child(3) div span,
.tablediv div div div:nth-child(1) div,
.pendingtable div div div:nth-child(1) div {
    font-weight: bold !important;
}

.service_capture .borderradius {
    border-left: unset !important;
    border-top-left-radius: 0 !important;
}

.service_capture .custom-style-datepicker {
    border-top-right-radius: 0 !important;
    border-right: unset !important;
    border-bottom-right-radius: 0 !important;
}

.purchaseOrderView .tfoot-view-wo td.amount-col {
    padding-right: 21px !important;
}

.purchaseOrderView .tfoot-view-wo td.amount-col:last-child {
    padding-right: 13px !important;
}

.service_capture #reportrange {
    text-align: left;
    max-width: 220px;

    &>span {
        width: 92% !important;
        padding-left: 10px;
    }
}

.service_capture {
    .download_button {
        width: 90px;
        margin-top: -3px !important;
        padding: 8px 0px !important;
    }
}

.service_capture {
    .Search_Vin_btn {
        width: 90px;
        margin-top: 0px !important;
        padding: 8px 0px !important;
    }
}

.btn.btn-primary.btn-fill.btn-sm.dboardedit {
    background-color: white;
    border: 0px;
}

.dashboardedit-btn {
    color: #1568d1 !important;
    font-size: 19px !important;
}

.dashboarddelete-btn {
    color: #c42f2b;
    font-size: 19px;
}

.dashboardedit {
    background-color: transparent !important;
    border: unset !important;
    padding: 0px !important;
}

// .vendorPriceModal {
//     #alert-dialog-title h2 {
//         font-weight: bold !important;
//     }
// }

#alert-dialog-title h2 {
    font-size: 15px;
}

#commonTable {
    position: relative;

    .RootBase-root-custom {
        width: 100%;
    }
}

#productTable {
    .no-box-shadow {
        box-shadow: unset;
    }
}

#mat-custom-Table {
    .no-box-shadow {
        .MuiPaper-root {
            box-shadow: unset;
            //padding: unset;
        }

        .box-shadow {
            box-shadow: unset;
        }
    }
}

.survey_list_container #productTable {
    .btn-info.btn-fill {
        color: #ffffff;
        background-color: #1dc7ea !important;
        opacity: 1;
    }

    .btn-danger.btn-fill {
        color: #ffffff;
        background-color: #ff4a55 !important;
        opacity: 1;
        filter: alpha(opacity=100);
    }
}

.purchaseOrder {
    .error-msg-order+.error-msg-order {
        bottom: -25px !important;
    }
}

.dashboard_recent_workorder table thead tr:first-child th:last-child {
    text-align: center !important;
}

.dashboard_recent_workorder table thead tr:first-child th:last-child svg {
    display: none;
}

.dashboard_recent_workorder table tbody tr td:last-child {
    .w-71px {
        width: auto;
    }

    text-align: center !important;
}

div.jss2>div {
    font-size: 12px !important;
}

.collapse.show {
    border: 1px #cccccc52 solid;
    padding: 5px 4px;
    margin: 10px 0px;
    border-radius: 5px;
}

span.custom-link-value {
    background: #3472f7;
    color: #fff;
    border-radius: 3px;
    margin: 2px;
    padding: 2px 6px;
}

.password-main {
    position: relative;
}

.password-input {
    padding-right: 36px;
}

.password-icon {
    position: absolute;
    z-index: 99;
    right: 10px;
    color: #493989;
    top: 38px;
}

.upload-document-container {
    display: block;
    height: auto;
    overflow: hidden;

    .btn-download-pdf {
        margin-right: 8px;
        margin-bottom: 6px;
        font-size: 12px;
        padding: 5px 10px;
    }
}

#image-upload-container {
    .nav-tabs {
        li {
            margin-right: 3px !important;
        }
    }

    .upload-doc-section {
        height: auto;
        margin: 15px 0% 2px;

        .file-upload-image-con {
            text-align: center;
            background-color: whitesmoke;
            border: 2px dashed #c4c4c4;

            .file-upload-image-section {
                cursor: pointer;

                img {
                    padding: 30px 0;
                }
            }
        }

        &.empty-section {
            text-align: center;
            height: 200px !important;
            font-size: 14px;
            padding-top: 21px;
        }
    }

    .attached-list {
        display: table;
        position: relative;
        margin: 15px 0% 2px;

        .attached-list-title {
            font-size: 16px;
            font-weight: 600;
            color: #5f5f5f;
            margin-bottom: 5px;
        }

        .files-list-con {
            display: list-item;
            padding: 5px 30px 6px 18px;
            position: relative;
            list-style-type: none;

            .remove-file {
                position: absolute;
                right: 0;
                top: 5px;
                border: 1px solid #ccc;
                padding: 2px 5px 1px 5px;
                border-radius: 50%;
                font-size: 10px;
                font-weight: 600;
                background-color: red;
                cursor: pointer;
                color: #fff;
            }
        }
    }

    .nav-tabs {
        text-align: center;

        li {
            display: inline-block;
            float: none;

            a {
                color: #727171;
                font-weight: 600;
                padding: 10px 25px;
                cursor: pointer;
                background: #e5e5e5;
            }
        }

        li.active {
            a {
                color: #535353;
                background-color: #81de1b;
            }
        }
    }

    .custom-tabs {
        .carousel-slider {
            margin-top: 20px;

            .slide {
                background-color: #ffffff !important;

                .image-slider {
                    text-align: center;
                    height: 400px;
                    pointer-events: none;
                    margin-bottom: 25px;

                    img {
                        height: 100%;
                        width: auto;
                        border: 0;
                    }

                    p.fileName-label {
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        font-size: 12px;
                        width: 100%;
                    }
                }
            }

            .control-arrow {
                padding: 5px 10px;
                background: transparent;

                &.control-next {
                    &:before {
                        border-left: 22px solid #716e6e !important;
                        border-top: 22px solid transparent;
                        border-bottom: 22px solid transparent;
                    }
                }

                &.control-prev {
                    &:before {
                        border-right: 22px solid #716e6e !important;
                        border-top: 22px solid transparent;
                        border-bottom: 22px solid transparent;
                    }
                }

                &:hover {
                    background: transparent !important;
                }
            }
        }

        .carousel {
            .thumbs-wrapper {
                margin-left: 35px;
                margin-right: 35px;
                margin: 20px;
                overflow: hidden;
                margin-top: 10px;
                margin-bottom: 0;

                &:hover {
                    background: transparent !important;
                }

                .thumb {
                    border: none;
                    padding: 3px;
                    box-shadow: 0 0 5px 1px #cecece;
                    width: 100px;

                    img {
                        height: 100%;
                        object-fit: contain;
                        padding: 2px;
                    }

                    &:hover {
                        border: 1px solid 1px solid #8d8a8a;
                    }
                }

                .control-arrow {
                    &.control-next {
                        &:before {
                            border-left: 8px solid #000;
                        }
                    }

                    &.control-prev {
                        &:before {
                            border-right: 8px solid #000 !important;
                        }
                    }
                }
            }
        }

        .attachments-btn {
            position: relative;

            p {
                position: absolute;
                z-index: 12;
                height: 32px;
                top: -10px;
                cursor: pointer;
                background-color: white;
                border-radius: 50%;
                padding: 6px;
                box-shadow: 0 0 4px 0px #bababa;

                img {
                    height: 100%;
                }

                &.download-btn {
                    right: 70px;
                }

                &.delete-btn {
                    right: 30px;
                }
            }
        }
    }
}

input.form-control::-webkit-contacts-auto-fill-button,
input.form-control::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.reject-sec-value {
    margin-top: 13px;
}

.inline-field {
    .inlineSelectArray1 {
        width: 35%;
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
    }

    .inlineSelectArray2 {
        width: 60%;
        display: inline-block;
        margin-left: 0px;
        vertical-align: top;
    }
}

.time_picker_main_container {
    position: relative;

    &.disabled:after {
        content: "";
        position: absolute;
        top: 0;
        background: #ebebeb78;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: not-allowed;
    }
}

.Time-Picker-DealerService {
    .time_picker_main_container {
        .time_picker_container {
            .time_picker_preview {
                box-shadow: none;
                border: 1px solid #d3d3d3;
                height: 40px;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                .preview_container {
                    height: 23px;
                    line-height: 39px;
                    width: 105px;

                    svg {
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        top: 7px;
                        left: 0;
                    }
                }
            }
        }
    }
}

.docu-home-container {
    .flex-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 62%;
        margin: 100px 15px;
        border: 1px solid #ddd;

        .docu-home-body {
            text-align: center;
            font-family: sans-serif;

            .head {
                font-size: 23px;
                color: #5b5b5b;
                font-weight: bold;

                .fa-info-circle {
                    margin-right: 9px;
                    color: #d8c51b;
                }
            }

            .lbl {
                font-size: 14px;
                font-weight: bold;
                color: #5c6b41;
            }
        }
    }
}

.docu-momdal-con {
    padding: 35px 35px 65px !important;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #d8d8d8;
}

.docu-momdal-footer {
    justify-content: center !important;
    padding: 7px 0;
}

div.form-control.disabled {
    background-color: rgba(221, 220, 220, 0.38);
}

.docu-thanks {
    text-align: center;
}

#workOrderListModal,
#HospitalListModal {
    text-align: center;
}

span.disabled.form-control {
    pointer-events: none;
    background-color: #f5f5f5;
    color: #888888;
    cursor: not-allowed;
}

.react-bootstrap-daterangepicker-container.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
    color: #888888;
    cursor: not-allowed !important;
}

.time_picker_preview.disabled {
    .wrapper {
        background-color: #f5f5f5;
        color: #888888;
    }
}

.documents-modal {
    .image-modal-section {
        padding: 0 24px 10px !important;

        span.close-image-modal {
            position: absolute;
            right: 15px;
            top: 15px;
            background: red;
            padding: 7px 5px;
            color: #fff;
            border-radius: 50px;
            cursor: pointer;
        }
    }

    .custom-modal-footer {
        justify-content: center;
    }

    #alert-dialog-title h2 {
        font-size: 18px !important;
        text-align: center;
        font-weight: bold;
        color: #595959;
        padding-bottom: 10px;
    }

    .custom-modal-footer {
        button.btn {
            background-color: transparent;
            border: 1px solid #757373;
            font-weight: bold;
            border-radius: 25px;

            &:active,
            &:focus,
            &:hover {
                box-shadow: 1px 1px 5px 1px #dddcdc !important;
            }
        }

        .btn-upload-file {
            color: #5a9100 !important;
            margin-right: 10px;
        }

        .btn-close-file {
            color: red !important;
            margin-right: 30px;
        }
    }
}

.row.statu-bar-container {
    display: flex;
    margin: 10px 0;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .statu-bar {
        height: 45px;
        flex: 1 1 100%;
        text-align: center;
        cursor: default;
        background: #c0c0c0;
        -webkit-clip-path: polygon(10% 50%, 0% 0%, 90% 0%, 100% 50%, 90% 100%, 50% 100%, 0% 100%);
        clip-path: polygon(10% 50%, 0% 0%, 90% 0%, 100% 50%, 90% 100%, 50% 100%, 0% 100%);

        &.active {
            background: #eabd1d;
        }

        &.completed {
            background: #16cb56;
        }

        &.completed.rejected {
            background: #c0c0c0;
            color: #000;
        }

        &.active.completed.rejected {
            background: #ea4747;
            color: #fff;
        }

        .statu-bar-text {
            z-index: 10;
            font-size: 12px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            padding: 0 20px;
        }
    }
}

.area-manager-mail-container {
    display: flex;
    align-items: baseline;
    border-top: 1px solid #ddd;
    margin-left: -24px;
    margin-right: -24px;
    padding: 15px 20px 0;
    position: relative;

    &:after {
        content: "";
        border-bottom: 1px solid #ddd;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15px;
    }

    span.inline-error {
        font-size: 12px;
        color: red;
        margin: 0px 2px;
    }
}

.workers-claim-edit {
    .side-drawer-container {
        position: fixed;
        right: 0;
        top: 26%;
        background: #dadada;
        width: 38px;
        border-radius: 15px 0px 0px 15px;
        padding: 10px 3px 5px 12px;
        color: #4b4b4b;
        z-index: 9;

        .custome-border {
            padding: 0;

            li {
                list-style-type: none;
                padding: 4px 0px 3px;
                cursor: pointer;
                text-align: center;
                width: 24px;
                margin-left: -4px;
                margin-bottom: 3px;
                border-radius: 50%;
                font-weight: bold;
                font-size: 12px;

                &.active {
                    background-color: #949494;
                    border: 1px solid #ddd;
                    color: #fff;
                }

                &:hover {
                    background-color: #afafaf;
                    color: #fff;
                }
            }
        }
    }

    .visitor-view-form {
        .checkbox-label {
            display: flex;
            align-items: center;

            input {
                width: 18px;
                height: 18px;
                padding: 0;
                margin: 0;
                vertical-align: bottom;
                position: relative;
                margin-top: 15%;
            }

            label {
                padding: 3px 0px 0 8px;
                margin: 0;
            }

            .inline-input {
                input {
                    height: 35px;
                    width: 80px;
                    margin-left: 5px;
                    padding: 5px;
                }
            }

            &.days3-date-con {
                .react-bootstrap-daterangepicker-container {
                    span.form-control {
                        width: 100px;
                        margin-left: 5px;
                        padding: 8px 5px;
                    }
                }
            }

            .other-input {
                width: 58%;

                input {
                    height: 35px;
                    width: 95%;
                    margin-left: 10px;
                    padding: 5px;
                }
            }

            &.col-md-3 {
                .other-input {
                    width: 72%;
                }
            }
        }

        .row {
            &.no-margin {
                margin: 0;
            }

            .padding-left-7 {
                padding-left: 7px;
            }

            .padding-left-15 {
                padding-left: 15px;
            }

            .margin-left {
                margin-left: 10px;
            }

            .defalut-formGroup {
                .form-group {
                    margin-left: 8px;
                    margin-bottom: 0;
                }

                &.second {
                    .form-group {
                        margin-left: 0px;
                    }
                }
            }

            .no-margin-formGroup {
                .form-group {
                    margin-left: 0px;
                }

                &.second {
                    .form-group {
                        margin-left: 0px;
                    }
                }
            }
        }

        .custom-error {
            padding-left: 8px;
            color: red;

            &.wrap-inline {
                padding-left: 15px;
            }

            &.padding-none {
                padding-left: 0px;
            }
        }

        .section0-error {
            padding-left: 0;
            margin-left: -5px;
            padding-top: 2px;
        }

        .padding-remove {
            padding-top: 0;
            margin-top: -5px;
            padding-bottom: 0;
        }

        .m-b-15 {
            margin-bottom: 15px;
        }

        .m-b-25 {
            margin-bottom: 25px;
        }

        .m-t-15 {
            margin-top: 15px;
        }

        .full-legth {
            input {
                width: 100%;
                height: 100%;
                padding: 8px 8px;
            }
        }

        .section0 {
            background-color: #7dd9ecc2;
            height: 35px;
            padding-top: 0;
        }

        .remove-padding {
            padding-bottom: 0 !important;

            .control-label {
                margin-bottom: 0;
            }
        }

        .ml5 {
            margin-left: 10px;
        }

        .wrapFormGroup {
            margin-bottom: 0;
            margin-top: 10px;

            .wrapRow {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .header-color {
            background-color: #7dd9ecc2;
        }

        .margin0 {
            margin: 0;
        }

        .time_picker_container {
            .react_times_button.time_picker_preview {
                box-shadow: none;
                border: 1px solid #d3d3d3;
                height: 43px;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                .wrapper {
                    .preview_container {
                        height: 39px;
                        line-height: 46px;
                        width: 105px;

                        svg {
                            top: 8;
                        }
                    }
                }
            }
        }
    }

// ---------------
    .workers-claim-form {
        .checkbox-label {
            display: flex;
            align-items: center;

            input {
                width: 18px;
                height: 18px;
                padding: 0;
                margin: 0;
                vertical-align: bottom;
                position: relative;
            }

            label {
                padding: 3px 0px 0 8px;
                margin: 0;
            }

            .inline-input {
                input {
                    height: 35px;
                    width: 80px;
                    margin-left: 5px;
                    padding: 5px;
                }
            }

            &.days3-date-con {
                .react-bootstrap-daterangepicker-container {
                    span.form-control {
                        width: 100px;
                        margin-left: 5px;
                        padding: 8px 5px;
                    }
                }
            }

            .other-input {
                width: 58%;

                input {
                    height: 35px;
                    width: 95%;
                    margin-left: 10px;
                    padding: 5px;
                }
            }

            &.col-md-3 {
                .other-input {
                    width: 72%;
                }
            }
        }

        .row {
            &.no-margin {
                margin: 0;
            }

            .padding-left-7 {
                padding-left: 7px;
            }

            .padding-left-15 {
                padding-left: 15px;
            }

            .margin-left {
                margin-left: 10px;
            }

            .defalut-formGroup {
                .form-group {
                    margin-left: 8px;
                    margin-bottom: 0;
                }

                &.second {
                    .form-group {
                        margin-left: 0px;
                    }
                }
            }

            .no-margin-formGroup {
                .form-group {
                    margin-left: 0px;
                }

                &.second {
                    .form-group {
                        margin-left: 0px;
                    }
                }
            }
        }

        .custom-error {
            padding-left: 8px;
            color: red;

            &.wrap-inline {
                padding-left: 15px;
            }

            &.padding-none {
                padding-left: 0px;
            }
        }

        .section0-error {
            padding-left: 0;
            margin-left: -5px;
            padding-top: 2px;
        }

        .padding-remove {
            padding-top: 0;
            margin-top: -5px;
            padding-bottom: 0;
        }

        .m-b-15 {
            margin-bottom: 15px;
        }

        .m-b-25 {
            margin-bottom: 25px;
        }

        .m-t-15 {
            margin-top: 15px;
        }

        .full-legth {
            input {
                width: 100%;
                height: 100%;
                padding: 8px 8px;
            }
        }

        .section0 {
            background-color: #7dd9ecc2;
            height: 35px;
            padding-top: 0;
        }

        .remove-padding {
            padding-bottom: 0 !important;

            .control-label {
                margin-bottom: 0;
            }
        }

        .ml5 {
            margin-left: 10px;
        }

        .wrapFormGroup {
            margin-bottom: 0;
            margin-top: 10px;

            .wrapRow {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .header-color {
            background-color: #7dd9ecc2;
        }

        .margin0 {
            margin: 0;
        }

        .time_picker_container {
            .react_times_button.time_picker_preview {
                box-shadow: none;
                border: 1px solid #d3d3d3;
                height: 43px;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                .wrapper {
                    .preview_container {
                        height: 39px;
                        line-height: 46px;
                        width: 105px;

                        svg {
                            top: 8;
                        }
                    }
                }
            }
        }
    }
}


.dynamic-form-container {

    .add-new-btn-continer,
    .close-icon {
        display: flex;
        justify-content: flex-end;
        padding-right: 7px;
        font-size: 21px;
        height: 21px;
        margin-bottom: -3px;

        i.fa {
            cursor: pointer;
        }
    }

    .add-new-btn-continer {
        color: #028b02;

        .disabled {
            .fa {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .close-icon {
        color: #e62727;
    }

    .dyno-form {
        .dyno-form-sections {
            border-top: 1px solid #eee;
            padding-top: 6px;
        }
    }
}

.othersCheckBox {
    padding: 0px !important;
}

.OthersGroupCheckbox {
    padding: 0px 6px 10px !important;
}

.SeverityOtherCheckBox {
    padding: 0px 15px 15px !important;
}

.Sec-12-class div:nth-child(2) {
    padding-left: 12px;
}

.header-label .control-label {
    font-weight: bold !important;
}

.rdw-editor-main {
    height: 275px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

// .color-header-div{
//     background-color: #7dd9ecc2;
// }

.info-sec {
    padding-top: 8px;
}

.PD-Header {
    text-align: center;
    display: block;
}

.PD-Header h5 {
    font-weight: bold;
}

.RichTextBox {
    // padding: 0px 10px 15px 10px;
    padding: 0px 0px 15px 70px;
}

.Safety-Policy {
    padding: 0px 10px 15px 10px;
}

.Safety-Policy .ql-container,
.model-container .ql-container {
    height: 100% !important;
}

.RichTextBox-View {
    box-sizing: content-box;
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    /* display: flex; */
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    width: 100%;
}

.model-container {
    width: 100%;
}

.model-close-icon {
    color: red !important;
    float: left;
    padding: 0px !important;
    //padding-left: 937px !important;
    justify-content: flex-end !important;
    padding-top: 0px !important;
}

.model-close-icon:hover {
    background-color: unset !important;
}

#HospitalListModal {
    padding: 0px 24px 20px !important;
}

.ql-container {
    height: 275px !important;
}

.portal-icons {
    display: flex;
    height: 99%;
    align-items: center;

    .portal-icons-continer {
        text-align: center;
        width: 100%;
        margin-top: -60px;

        .qs-logo-name {
            img {
                width: calc(100vw / 4);
            }

            .qs-lbl {
                font-size: 28px;
                padding: 10px 3px 0;
                font-family: Roboto;
            }
        }

        .portal-block {
            display: inline-block;
            text-align: center;
            width: calc(100vw / 7);
            font-size: calc(100vw / 80);
            margin: calc(100vw / 35);
            vertical-align: middle;
            padding-top: calc(100vw / 40);
            padding-bottom: calc(100vw / 60);
            box-shadow: 0 0 6px 0px #595959;
            border-radius: 10px 10px;
            cursor: pointer;

            &.portal-block.qs {
                background: rgb(162, 134, 192);
            }

            &.portal-block.co {
                background: rgb(130, 202, 156);
            }

            &.portal-block.cp {
                background: rgb(250, 173, 129);
            }

            &:hover {
                box-shadow: 0 0 9px 1px #969696;
                padding-top: calc(100vw / 40);
                padding-bottom: calc(100vw / 60);
            }

            img {
                width: 57%;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
            }

            .portal-lbl {
                padding: 8px 10px 4px;
                font-weight: bold;
            }
        }
    }
}

.workers-error-modal {
    span {
        display: block;
        padding: 4px 0px 0;
        text-align: right;
    }

    textarea {
        padding: 12px;

        &:focus {
            outline: none;
        }
    }
}

span.inline-ids-text {
    color: #426bc4;
    font-weight: bold;
}

.url-link {
    cursor: pointer;
    color: #246de3;
}

/* Overide Reponsive Section */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    /* Styles */
    .Section-0thClass {
        padding-bottom: 40%;
    }

    .OthersGroupCheckbox {
        padding: 7px 15px 10px !important;
    }

    .workers-claim-edit .workers-claim-form .ml5 {
        margin-left: unset !important;
    }

    .Sec-12-class div:nth-child(2) {
        padding-left: inherit !important;
    }

    .othersCheckBox {
        padding: 14px !important;
    }

    .workers-claim-edit .workers-claim-form .row .margin-left {
        margin-left: unset !important;
    }

    .time_picker_main_container.disabled,
    .Years-select-container {
        margin-bottom: 12px;
    }

    .section31-container.col-md-6 {
        margin-top: 10px;

        .margin0 {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .section24-container .checkbox-label {
        margin: 5px 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    /* Styles */
    .Section-0thClass {
        padding-bottom: 18%;
    }

    .OthersGroupCheckbox {
        padding: 7px 15px 10px !important;
    }

    .workers-claim-edit .workers-claim-form .ml5 {
        margin-left: unset !important;
    }

    .Sec-12-class div:nth-child(2) {
        padding-left: inherit !important;
    }

    .othersCheckBox {
        padding: 14px !important;
    }

    .workers-claim-edit .workers-claim-form .row .margin-left {
        margin-left: unset !important;
    }

    .time_picker_main_container.disabled,
    .Years-select-container {
        margin-bottom: 12px;
    }

    .section31-container.col-md-6 {
        margin-top: 10px;

        .margin0 {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .section24-container .checkbox-label {
        margin: 5px 0;
    }
}

.EmailOrPhoneLabel {
    display: block;
    margin-bottom: 10px;
}

.EmailOrPhone {
    vertical-align: top;
    display: inline-block;
    width: 25%;
    //margin-left: 10px;
}

.EmailOrPhoneInputField {
    display: inline-block;
    width: 65%;
    margin-left: 10px;
}

.dealer_service_master_container {
    .container-fluid {
        .location-container {
            margin-top: 20px;

            .location-field {
                text-align: left;
                font-size: 22px;
            }

            .control-label {
                display: inline-block;
                float: left;
                font-size: 22px !important;
                color: #000 !important;
            }
        }

        form.service-form {
            border: 1px solid #ececec;
            padding: 5px 30px 15px;
            margin-top: 15px;
            margin-bottom: 0;
            border-radius: 3px;
        }

        .table-name-lable {
            span {
                font-size: 20px;
                color: red;
            }

            color: #585858;
        }
    }

    .section.mt-10 {
        margin-bottom: 10px;
    }

    .add-service-btn-container {
        margin-top: 6px;
        text-align: center;
    }

    .control-label {
        margin-top: 5px;
    }

    .form-group {
        margin-bottom: 0px;
    }

    .inline-checkbox-container {
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 25px;

        .control-label {
            display: inline-block;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .inline-checkbox {
            display: inline-block;
            padding: 1px 8px 8px 14px;

            .form-control {
                width: 16px;
                height: 16px;
            }
        }
    }

    .table-name-lable {
        margin-top: 25px;
        font-size: 16px;
        margin-bottom: 4px;
        font-weight: bold;
        color: #736dc3;
    }
}

.content.service_capture_home {
    .Capture-Service.card-body {
        background: rgba(221, 221, 221, 0.7);
        border-radius: 5px;

        .display-none {
            display: none;
        }

        .back-button {
            margin: 20px 0;
        }
    }

    i.fas.fa-book {
        padding-right: 5px;
    }

    .card {
        form {
            padding: 5px 0px 0;

            .row {
                margin: 0;
            }

            [class*="col-"] {
                position: inherit;
                padding-top: 16px;
                padding-left: 10px;
            }
        }

        &.mt-10 {
            margin-top: 10px;
        }
    }

    .foot-btns-container {
        padding: 0px 0px 20px;

        .view-btn-center {}
    }

    .captureServiceTable {
        padding: 15px 10px 0;
        padding-top: 10px;

        .table>thead>tr>th {
            padding: 3px 4px;
        }

        .table thead tr:first-child {
            height: 30px;
        }

        .table {
            tr {
                input.inline-check-item {
                    height: 15px !important;
                    width: 15px !important;
                    cursor: pointer;
                }

                th input.inline-check-item {
                    height: 16px !important;
                    width: 16px !important;
                    cursor: pointer;
                }

                .service-action-td {
                    .tbl-inline-btn {
                        width: 24px !important;
                        height: 24px !important;
                        background: transparent;
                        border: none;

                        &:focus {
                            outline: 0;
                        }

                        .table-survey-button {
                            margin: -5px 5px;
                            width: 21px;
                        }
                    }

                    .btn-danger.btn-circle,
                    .btn-verify.btn-circle {
                        width: 19px !important;
                        height: 19px !important;
                        margin-left: 10px;

                        .fa-times,
                        .fa-reply {
                            font-size: 10px;
                            padding: 0px 3px 1px 0px;
                        }
                    }
                }
            }

            p.comments-cell {
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 3px;
            }

            .remove_restore {
                background: #fdfd10;
                border: 2px solid #dddd2f;

                .comments-cell-container {
                    position: relative;

                    p.comments-cell {}

                    p.remove {
                        font-size: 10px !important;
                        display: inline-block;
                        line-height: 1.2;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

    .mail-btn {
        display: inline;
    }

    .purchase .col-12-xs span {
        padding-left: 5px;
    }
}

.capture-vin-modal {
    .capture-modal-container {
        margin-top: 0px;
        padding: 40px 30px !important;
        overflow: visible;

        .content1 {
            padding: 0px 0px 20px;
            color: #ff0000;
            font-size: 15px;
            font-weight: 600;
        }

        .content2 {
            padding: 0px 0px 10px;

            .row {
                margin-top: 11px;
            }
        }

        .po-num-con {
            margin-top: 10px;
        }

        .content3 {
            margin-top: 15px;

            .cn-lbl {
                padding-bottom: 8px;
            }

            &.reason-input {
                padding-top: 5px;
            }
        }
    }

    .capture-modal-footer {
        justify-content: center;
        padding-bottom: 16px;
        display: block;
        text-align: center;

        .btn.btn-fill {
            margin-bottom: 10px;
        }
    }
}

.quick_book_service_home {
    .card {
        form {
            padding: 15px 20px 0;

            .field-button {
                margin-top: 20px;
            }

            span.error.inline-error {
                display: block;
                color: red;
                font-size: 12px;
                padding-left: 3px;
                margin-top: 3px;
            }
        }

        .lable {
            margin: 20px 10px 8px;
            font-size: 16px;
            font-weight: bold;
            color: #446eca;
        }
    }
}

.master_base_component {
    thead {
        tr {
            td {
                font-weight: bold;
            }
        }
    }

    tr {
        td {
            padding: 0 !important;
            font-size: 14px;

            .table-inline-field {
                .form-control {
                    width: 90%;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 12px !important;
                }

                .select-form-control {
                    width: 90%;
                    margin-top: 5px;
                    margin-bottom: 5px;

                    div {
                        font-size: 12px !important;
                    }
                }

                span.error.table-error {
                    display: block;
                    color: red;
                    font-size: 12px;
                    padding-left: 3px;
                    margin-top: -2px;
                }
            }
        }
    }
}

.common-modal-body {
    .default-mail-con {
        margin-bottom: 9px;
        padding-left: 16px;
        color: #5d5d5d;

        span {
            font-weight: bold;
        }
    }

    .service-mail-con {
        margin-bottom: 14px;
        padding-left: 16px;
        padding-top: 2px;

        .control-lable {
            display: inline-block;
            padding-right: 7px;
            vertical-align: top;
            padding-top: 10px;
        }

        .service-mail-select {
            display: inline-block;
            width: 40%;

            span.err {
                font-size: 12px;
                color: red;
                padding-left: 5px;
            }
        }
    }
}

.service_download_home {
    .filter-form-container {
        .filter-form-card {
            padding: 10px 20px;
            margin-bottom: 0px;

            .output-file-name {
                font-size: 16px;
                text-align: left;
                font-weight: bold;
                margin-bottom: 5px;
                color: #8894d8;
                margin-top: 3px;
            }

            .card.second-card {
                padding: 10px 20px 0;
                background: #efefef;
                margin-bottom: 10px;

                .location {
                    .back-button {
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }
                }
            }

            .react-bootstrap-daterangepicker-container {
                .form-control.date-range-picker {
                    span {
                        padding-left: 7px;
                    }

                    i.far.fa-calendar-alt.pull-right-position {
                        padding-right: 10px;
                        padding-top: 3px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .content.service_capture_home {
        .table-responsive {
            overflow-y: hidden;
            overflow-x: auto;
        }
    }
}

@media (min-width: 1200px) {
    .md-45p {
        width: 45% !important;
    }
}

.Reports_table .tablediv .MuiToolbar-root {
    padding-bottom: 8px !important;
    min-height: 0px !important;
}

.Reports_table .tablediv .MuiToolbar-root div:nth-child(2) {
    font-weight: bold !important;
    font-size: 14px !important;
    color: #333;
}

.VinDecode .glyphicon {
    display: unset !important;
}

.VinDecode {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.service-capture-model div:nth-child(2) div:nth-child(1) {
    overflow-y: unset !important;
}

.summary-hr {
    margin-top: unset !important;
    margin-bottom: unset !important;
}

.notification-success {
    background-color: #04a004 !important;
}

.sidebar .nav {
    margin: 5px 10px !important;
}

.sidebar .nav p {
    line-height: 25px !important;
}

.custom_Footer {
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: right !important;
    color: black !important;
}

.video-modal .MuiPaper-root {
    max-width: 500px;
    height: 60%;
}

.canvas-video-container {
    display: block;
    text-align: center;

    .video-overlayer {
        width: 300px;
        overflow: hidden;
        display: inline-block;
        position: relative;
        height: 390px;

        video {
            display: inline-block;
            vertical-align: baseline;
            align-items: center;
            position: absolute;
            left: auto;
            right: 0;
            top: 16px;
        }
    }

    .enable-cors-error {
        padding: 100px 0;
        color: red;

        span {
            color: #1f8e1f;
            font-weight: bold;
            font-size: 15px;
            cursor: pointer;
        }
    }
}

#RBS-Scheduler-root {
    margin-left: 0;
    margin-top: 0;

    .scheduler-view {
        .scheduler-bg-table {
            th.header3-text {
                text-align: center;
            }
        }
    }

    .scheduler-bg {
        .scheduler-bg-table {
            tbody {
                tr td {
                    border-right: 1px solid #ddd;
                }
            }
        }
    }
}

.col-md-12.custom-responsive-width {
    overflow: scroll;
}

.scheduler-event-editor {
    margin-bottom: 15px;

    .scheduler-event-header {
        padding: 10px 10px 10px;
        background-color: #e6e6e6;
        margin-bottom: 10px;

        .scheduler-checkBox-container {
            display: inline-block;
            vertical-align: middle;
            margin-left: 25px;

            i.fa-check {
                padding: 4px 0px 0px 8px;
            }
        }

        .label-header {
            width: 84%;
            text-align: center;
            display: inline-block;
            font-size: 18px;
        }
    }

    .scheduler-event-container {
        // display: flex;

        .scheduler-event {
            display: flex;
        }

        .date-pk-container {
            .react-datepicker-wrapper {
                display: flex;
                width: 100%;

                .react-datepicker__input-container {
                    display: block;
                    width: 100%;

                    input {
                        background-color: #ffffff;
                        border: 1px solid #d1d1d1;
                        border-radius: 4px;
                        color: #565656;
                        padding: 8px 12px;
                        height: 35px;
                        box-shadow: none;
                        width: 150;
                    }
                }
            }

            .react-datepicker {
                font-size: 12px;

                .react-datepicker__current-month {
                    font-size: 12px;
                }

                .react-datepicker__day,
                .react-datepicker__time-name {
                    width: 2rem;
                    line-height: 2rem;
                }
            }
        }

        span.error {
            color: red;
            font-size: 12px;
            margin: 2px 3px -10px;
            display: block;

            &.second-level {
                // padding-top: 5px;
            }
        }
    }

    .scheduler-event-footer {
        justify-content: center;
    }

    .video-modal .MuiPaper-root {
        max-width: 500px;
        height: 100%;
    }

    .divider {
        margin: 5px 0 15px;
        background-color: #eeeeee;
        height: 1px;
    }
}

.ant-popover-inner-content {
    table {
        width: 100%;

        tr {
            td {
                padding: 5px;
                background: #fff;
                font-size: 13px !important;
                color: #626262;

                span {
                    text-transform: capitalize;
                    color: #3c763d;
                    font-weight: bold;
                }

                i.fa.fa-flag {
                    font-size: 12px;
                    color: #bfb309;
                }
            }

            &.border-bottom {
                border-bottom: 2px solid #b5b5b5;

                td {
                    padding-bottom: 10px;
                }
            }

            &.border-top {
                td {
                    padding-top: 10px;
                }
            }
        }
    }
}

.scheduler-content {
    .scheduler-content-table {
        .inner-line-item {
            padding-top: 0px;
            font-size: 9px !important;

            i.fa.fa-flag {
                font-size: 11px;
                color: #bfb309;
            }

            .extra-event-lable {
                color: #000;
                padding-left: 3px;
                padding-top: 3px;
                width: 100%;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 10px !important;
            }
        }

        .event-0 {
            .inner-line-item {
                padding-top: 0px;

                .event-item {
                    margin-top: 0px !important;
                }
            }

            &.conflicted-event {
                /* .inner-line-item {
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        background: #ffad000d;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        height: 80px;
                    }
                } */
            }
        }
    }
}

#mat-custom-Table {
    .MuiTable-root {
        .summary-footer-row {
            .summary-footer-cell {
                width: 150px;
                padding-left: 5px;
                font-size: 12px;
                font-weight: bold;
                color: #333;
            }
        }
    }
}

.box-shadow {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

#RBS-Scheduler-root {
    .resource-view {
        .resource-table {
            span.slot-text {
                font-size: 11px;

                i.fa.fa-flag {
                    font-size: 11px;
                    color: #bfb309;
                }
            }
        }
    }

    table.resource-table th,
    table.scheduler-table th,
    table.resource-table td,
    table.scheduler-bg-table td,
    table.scheduler-table td {
        border-right: none;
        border-bottom: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        z-index: 1;
        color: #d9d9d9;
        background: #e8e8e8;
        border-color: #d9d9d9;
        -webkit-box-shadow: -1px 0 0 0 #d9d9d9;
        box-shadow: -1px 0 0 0 #d9d9d9;
    }

    .slot-text {
        display: initial;
        padding-left: 4px;
        padding-right: 4px;
    }

    .ant-row-flex-middle {
        border: 1px solid #ddd;
        padding-top: 4px;
        border-bottom-color: transparent;
    }

    .overflow-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
        width: 170px;
        word-break: break-word;
        margin-left: 5px;
    }

    .header3-text div {
        font-size: 11px !important;
    }

    thead tr:nth-child(1) {
        background-color: transparent !important;
    }

    tr:nth-child(even) {
        background: transparent;
    }

    i.anticon.anticon-minus-square {
        font-size: 10px;
    }

    .resource-dealer-cls {
        background-color: rgb(232, 232, 232) !important;
    }

    .ant-col {
        margin-right: 5px;
    }

    .newCopyPasteLinks {
        #Navigator_CopyPasteContainer {
            button {
                margin-left: 5px;
                padding: 6px 8px;
                font-size: 13px;
                border: 1px solid #b3b3b3;
                border-radius: 3px;
                margin-bottom: 4px;
            }

            display: inline-block;
        }

        .information-block {
            .conflicts {
                font-size: 14px;
                margin-left: 5px;
                background: #efefef;
                padding: 5px 5px;
                border-radius: 3px;

                &.countEnabled {
                    background: #ffde9a;
                }
            }

            display: inline-block;
        }
    }
}

.sheduler-container {
    .card {
        .card-body {
            display: inline-block;
            padding-top: 12px;

            .form-group {
                label {
                    font-weight: 500 !important;
                    font-size: 14.5px !important;
                    margin-bottom: 1px;
                }
            }

            .xs-clear-both {
                .apply-btn {
                    margin-top: 20px !important;
                }
            }

            .pos-rel {
                position: relative;

                .form-group {
                    position: absolute;
                    z-index: 9;
                    width: 90%;
                }
            }
        }
    }
}

.popover-calendar {
    width: 235px !important;
    height: 235px;

    .react-datepicker {

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: #4a4a4a;
            display: inline-block;
            width: 2.9rem;
            line-height: 3rem;
            text-align: center;
            margin: 0.14rem;
            font-size: 12px;
        }

        .react-datepicker__day--selected {
            color: #fff;
            background-color: #a5a5a5;
        }

        .react-datepicker__day--in-range {
            color: #fff;
            background-color: #a5a5a5;
        }

        .react-datepicker__navigation {
            border: 0.5rem solid transparent;
            height: 11px;
            width: 11px;
        }

        .react-datepicker__navigation--next {
            border-left-color: #4a4a4a;
        }

        .react-datepicker__navigation--previous {
            border-right-color: #4a4a4a;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
            font-size: 12px;
        }
    }

    .DayPicker {
        width: 100%;
        height: 100%;

        .DayPicker-wrapper {
            .DayPicker-NavButton {
                width: 1.5em;
                height: 1.5em;
            }

            .DayPicker-Months {
                .DayPicker-Body {
                    .DayPicker-Week {
                        .DayPicker-Day {
                            padding: 9px 11px;
                            font-size: 11px;
                        }
                    }
                }

                .DayPicker-Caption {
                    margin-bottom: 10px;

                    div {
                        font-size: 13px;
                    }
                }

                .DayPicker-Weekdays {
                    .DayPicker-Weekday {
                        abbr {
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
    }
}

.Actual_Hrs_main {
    margin-bottom: 8px;

    .row {
        .Actual_Hrs {
            padding-left: 0px !important;
            padding-right: 5px !important;
        }

        .Actual_Min {
            padding-left: 5px !important;
            padding-right: 0px !important;
        }
    }
}

.Actual_Hrs {
    padding-top: 0px !important;
}

.Actual_Min {
    padding-top: 0px !important;
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.img-responsive,
.thumbnail a>img,
.thumbnail>img {
    height: 50% !important;
}

.resource-Name {
    padding-left: 10px !important;
}
.header-one {
    display: flex;
    margin-left: -21px;
    margin-right: -16px;
    margin-top: 6px;
}
.header-two {
    display: flex; 
    margin-top: -16px;
    margin-left: 8px;
    margin-right: -22px;
}

.user-view-edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

a.timeline-event.conflicted-event:after {
    content: "C";
    position: absolute;
    top: 7px;
    right: 1px;
    background: #ffac00;
    color: #060606;
    padding: 2px 5px;
    font-size: 8px;
    border-radius: 50px;
}

.event-error {
    font-weight: bold;
    color: red;
    font-size: 12px;
    margin-bottom: 3%;
    text-align: center;
}

.sheduler-container {
    .Summary-row {
        display: flex;
        margin-top: 15px;
        padding-bottom: 5px;

        .Summary-row-section {
            flex-direction: row;

            label.header-title {
                font-size: 14px !important;
                margin-bottom: 0;
            }

            .form-control-spage {
                background-color: #ffffff;
                border: 1px solid transparent;
                border-radius: 4px;
                color: #565656;
                padding: 0px 5px;
                box-shadow: none;
                margin: 0px 0px 0px 0px;
                width: 150px;
                display: inline-block;
            }
        }
    }
}

.prefixAddon-input {
    position: relative;

    span.prefixAddon {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 17px;
        background: #dcdcdc;
        border-radius: 5px 0 0 5px;
        font-weight: bold;
        color: #6f6f6f;
        z-index: 1;
    }

    input.prefixAddon-input.form-control {
        padding-left: 50px;
    }
}

#scheduler-label {
    font-size: 13px !important;
    font-weight: none !important;
    color: #444;
}

.video-feed {
    .col-md-3 {
        width: 20%;
        margin-top: 3px;
    }

    .col-md-8 {
        .search-box {
            background-color: #ffffff;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            color: #565656;
            padding: 8px 12px;
            height: 25px;
            box-shadow: none;
        }
    }
}

@media (max-width: 940px) {
    .video-feed {
        .col-md-3 {
            width: 30%;
            margin-left: 30px;
        }

        .col-md-8 {
            width: 80%;
            margin-left: 30px;
        }
    }
}

@media (min-width: 1200px) {
    .w-13 {
        width: 13.33333% !important;
    }
}


.select_readonly {
    pointer-events: none;
    background: #eee;
    -webkit-text-fill-color: #777;
}

.content {
    position: relative;

    .SendDocuBtn {
        margin-bottom: 22px;

        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}



.MuiDialogActions-spacing {
    justify-content: center !important;
}

.overlapImage {
    position: absolute;
    top: 15px;
    left: -84px;
    z-index: 9;
    height: 385px;
    width: auto;
}

.user-image-parent {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-image-clas {
        height: 330px !important;
    }

}
// Anand Css //
.schedule-data-loading{
    margin-top: 25px;
}
.schedule-data-loading-label {
    color: #c92f2b !important;
    font-weight: bold !important;
    font-size:medium;
}
#btnskip, #btnpreview, #btnprevious, #btnxt{
    background-color: #0078f9; 
    color: #f5f3f3;
}

.checkbox-label-list {
    display: flex;
    align-items: center;

    input {
        width: 18px;
        height: 18px;
        padding: 0;
        margin: 0;
        vertical-align: bottom;
        position: relative;
    }

    label {
        padding: 3px 0px 0 8px;
        margin: 0;
    }

    .inline-input {
        input {
            height: 35px;
            width: 80px;
            margin-left: 5px;
            padding: 5px;
        }
    }

    &.days3-date-con {
        .react-bootstrap-daterangepicker-container {
            span.form-control {
                width: 100px;
                margin-left: 5px;
                padding: 8px 5px;
            }
        }
    }

    .other-input {
        width: 58%;

        input {
            height: 35px;
            width: 95%;
            margin-left: 10px;
            padding: 5px;
        }
    }
}


 // Dynamic Feilds btn css

.dynamic-fields-btn {
    background-color: transparent !important;
    border: none !important;
    // margin: 19px 10px 0px 0px !important;
    margin-top: 19px !important;
}
.dynamic-fields-btn-1 {
    background-color: transparent !important;
    border: none !important;
    // margin: 19px 10px 0px 0px !important;
    // margin-top: 19px !important;
}


.circle-day-picker {
    display: flex;
    // justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 600;
    
  }
  
.circle-day {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
    margin: 2px 2px 5px 2px;
    justify-content: center;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    font-size: 12px;
    font-weight: 600;
    background-color: #f1f3f4;
    background-color: whitesmoke;
    color: #80868b;
    margin-right: 7px;
    }

.circle-day-selected {
    background-color: #3372f7;
    color: #ffffff;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.common-disable-div {
    opacity: 0.5; 
    // background-color: #f0f0f0;
    pointer-events: none;
}