.hidden {
    display: none;
}

#workOrderGroupContainer {
    .googlegraph {
        overflow-y: scroll;
    }
}

.desktop-d-flex {
    .workorder-top-details {
        .row.col-md-12 {
            margin-bottom: 30px;
        }
    }
}

.bottom_container {
    .card {
        margin-bottom: 0px !important;
    }
}

.marginTop20 {
    margin-top: 20px;
}

.chamicalCostContainer {
    #react-no-print {
        .col-lg-12.col-sm-12 {
            .card {
                margin-bottom: 0px;
            }
        }
    }
}


.reportDealerShipContainer {
    .desktop-d-flex {
        .card {
            margin-bottom: 0px !important;
        }

        .left-container {
            margin-top: 5px;
            margin-right: 4px;
            margin-bottom: 3px;
        }

        .right-container {
            margin-top: 5px;
            margin-left: 4px;
            margin-bottom: 3px;
        }
    }

    .custom-card {
        margin-bottom: 15px !important;
    }
}

@media (max-width: 992px) {
    #main-panel {
        .dealerTable {
            margin-top: 10px !important;
        }
    }

    .dashboard-title {
        font-size: 15px;
    }

    .content {
        .card {
            .container-fluid {
                form {
                    hr {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }

                    .button-group {
                        margin-bottom: 0px;

                        button.btn-primary.btn-fill {
                            margin-top: 0px !important;
                        }

                    }

                    .back-button {
                        margin-top: 10px !important;
                    }
                }
            }
        }
    }

    .responsive-card-container {
        .row {
            .card {
                margin-bottom: 10px !important;

                .card-body {
                    padding: 5px 15px 15px !important;

                    .Reportdealer-filter {
                        .apply-btn {
                            margin-top: 0px !important;
                        }
                    }
                }
            }
        }
    }


    .desktop-d-flex {
        .workorder-top-details {
            .col-md-12.row {
                margin-bottom: 0px;

                .col-sm-6 {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .AddNewSurvey {
        .card {
            .card-body {
                form {
                    .row {

                        .col-md-6,
                        .col-md-12 {
                            margin-bottom: 10px;
                        }
                    }
                }

                .survey-preview {
                    div {
                        h5 {
                            line-height: 18px;
                            margin: 10px 0 15px;
                            font-weight: 600;
                        }
                    }

                }

                .buttons-container {
                    .btn-secondary {
                        margin-right: 5px;
                    }
                }
            }
        }

    }

    // .tablediv {
    //     margin-top: 15px !important;
    // }

    .service_capture {
        .marginTop20.card {
            .Capture-Service {
                form {
                    .col-md-4 {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }


    .reportDealerShipContainer {
        .desktop-d-flex {
            .card {
                margin-bottom: 0px !important;
            }

            .workorder-top-details {
                .row {
                    margin-bottom: 0px;

                    .col-md-3 {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
@media (max-width: 959.95px) {
    .woTable .MuiTableCell-root[class*="MUIDataTableBodyCell-stackedParent-"] {
        display: contents !important;
    }
    .woTable .MuiTableCell-root.MuiTableCell-body {
        display: contents !important;
    }
}

/* Min mobile to max desktop  */
@media(max-width: 1200px) {

    // dashboard 
    .dashboard-container {
        .mob-filter_icon {
            display: none;
        }
    }

    #main-panel {
        .container-fluid {
            .dashboard-user-lable {
                position: absolute;
                width: 100%;
                text-align: center;
                top: 18px;
                color: #9A9A9A;

            }
        }
    }
}

@media(max-width: 1280px) and (min-width: 992px) {

    .responsive-card-container {
        .row {
            .card {
                margin-bottom: 10px !important;

                .card-body {
                    padding: 5px 15px 15px !important;

                    .Reportdealer-filter {
                        .col-lg-3.col-md-3 {
                            padding-right: 5px !important;
                            padding-left: 10px !important;
                        }

                        .xs-clear-both {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 992px) {
    .col-md-6.countdiv {
        padding-right: 0px;
    }
}

@media(min-width: 1200px) {

    // dashboard 
    .dashboard-container {
        .card {
            margin-bottom: 10px !important;
        }

        .row.dashboard-filter {
            padding-left: 0px;

            div.xs-clear-both {
                padding-left: 5px !important;
            }

            .col-md-2 {
                padding-left: 11px;
                padding-right: 5px;
            }
        }

        .countdiv.row {
            .col-lg-3.col-sm-6 {
                .card-body {
                    .col-md-8.col-sm-8.col-xs-6 {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }
        }

        .approval-pending-table {
            margin-bottom: 10px;
        }
    }

}

@media (max-width: 1200px) {

    // dashboard 
    .dashboard-container {
        .card {
            margin-bottom: 10px !important;
        }

        .countdiv.row {
            .col-lg-3.col-sm-6 {
                .card-body {
                    .col-md-8.col-sm-8.col-xs-6 {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }
        }

        .approval-pending-table {
            margin-bottom: 10px;
        }
    }

    .content.service_capture_home {
        .foot-btns-container {
            padding: 0px 0px 20px;

            .view-btn-center {
                span {
                    button {
                        margin-bottom: 10px !important;
                    }
                }
            }
        }
    }

}

@media (max-width: 600px) {
    #image-upload-container {
        .custom-tabs {
            .carousel-slider {
                .slide {
                    .image-slider {
                        height: 285px;
                    }
                }
            }
        }
    }

    .video-modal .MuiPaper-root {
        max-width: 90% !important;
        height: 85% !important;
        padding: 5px;
    }
}

@media(max-width: 568px) {
    .row.statu-bar-container {
        .statu-bar {
            &.start {
                clip-path: polygon(9% 49%, 0% 0%, 85% 0%, 100% 50%, 85% 100%, 50% 100%, 0% 100%);
            }
        }
    }
}

@media(max-width: 767px) {
    .row.statu-bar-container {
        .statu-bar {
            .statu-bar-text {
                font-size: 11px !important;
                height: 53px !important;
            }
        }
    }

    #image-upload-container {
        .custom-tabs {
            .carousel-slider {
                .slide {
                    .image-slider {
                        height: 340px;
                    }
                }
            }
        }
    }

    #mat-custom-Table {
        /* .datatables-noprint {
            width: 100%;
        } */
    }

}

/* Iphone upto Iphone6 */
@media(max-width: 480px) {

    #main-panel {
        .container-fluid {
            .dashboard-user-lable {
                top: 10px;

                span {
                    width: 145px;
                    display: inline-block;
                    font-size: 14px;
                }
            }
        }
    }

    .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
        .MuiTablePagination-caption {
            font-size: 10px;
        }

        .MuiTablePagination-selectRoot {
            margin-left: 8px;
            margin-right: 5px;
        }

        .MuiTablePagination-actions {
            .MuiIconButton-root {
                padding: 8px;
            }
        }
    }

    #mat-custom-Table {
        tbody {
            tr {
                td {
                    div {
                        
                    }
                }
            }
        }
    }
}


// Table design updated

.table-responsive.captureServiceTable {

    table tbody tr td,
    table th {
        font-size: 12px !important;
    }

    table tbody tr td,
    table th {
        padding: 2px 8px;
    }
}